<template>
  <div class="py-5">
    <div v-if="isDevEnvironment" class="dev-banner ps-5 py-2 fw-bold text-center" :class="{'dev-banner-local': devEnvironmentName == 'Local'}">
      {{ devEnvironmentName }} Development Environment
    </div>
    <slot></slot>
  </div>
  
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  emits: ['readOnlyLinkClicked'],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    pageTitle: {
      type: String,
      default: ""
    },
    alertClass: {
      type: String,
      default: "alert-success"
    }
  },
  created() {
    window['sitereg'].mainLayoutVmAuth = Auth;
    window['sitereg'].mainLayoutVm = this;
    this.customiseReactHeader();
  },
  updated() { // isAdmin is false initially; this lifecycle hook will trigger if it is subsequently set to true (so admin-only items will be added to header)
    this.customiseReactHeader();
  },
  data() {
    return {
      resetReactHeaderReadonlyItemLabel: true,
    }
  },
  computed: {
    devEnvironmentName() {
      if (location.host == "localhost:8080") {
        return "Local"
      } else {
        if (location.host == "dev.site-registry.qut.edu.au") {
          return "AWS"
        } else {
          return ""
        }
      }
    },
    isDevEnvironment() {
      return location.host == "localhost:8080" || location.host == "dev.site-registry.qut.edu.au";
    },
  },
  methods: {
    customiseReactHeader() {

      // wait for react header to render
      if (document.getElementById('qstNavigation') == null) {
        setTimeout(this.customiseReactHeader, 50);
        return;
      }

      // if isAdmin, add 'People' nav item (if it hasn't already been added by another Vue page)
      if (this.isAdmin && window['qutconfig']['navItems'].filter(function(item){return item.name && item.name == 'People'}).length == 0) {
        window['qutconfig']['navItems'].splice(2, 0, { name: "People", url: "/people" });
        document.getElementById('qutheadermain').dispatchEvent(new CustomEvent('qut-header-refresh'));
      }

      // update all nav items
      var ul = document.getElementById('qstNavigation');
      var links = ul.getElementsByTagName("a");
      for (var i=0; i < links.length; i++) {

        if (window['sitereg']['headerNonAdminNavItemEventListenersAdded'] == false || (links[i].attributes.href.value == "/people" && window['sitereg']['headerAdminNavItemEventListenersAdded'] == false)) {
          // add custom vue router listener (to avoid entire page reloading when nav items are clicked)
          links[i].addEventListener("click", function(event) {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById('qstNavigation').getElementsByTagName("a").forEach(function(obj) {obj.classList = ""});
            var route = event.currentTarget.href.substr(event.currentTarget.href.lastIndexOf("/"));
            window['sitereg']['rootAppVm'].$data.currentRoute = route;
            history.replaceState('', "page", route);
          });
          if (links[i].attributes.href.value == "/people") {
            window['sitereg']['headerAdminNavItemEventListenersAdded'] = true;
          }
        }

        // add/remove 'active' class to highlight the active nav item
        if (links[i].href.substr(links[i].href.lastIndexOf("/")) == window['sitereg']['rootAppVm'].$data.currentRoute) {
          links[i].classList = "active";
        } else {
          links[i].classList = "";
        }

      }
      window['sitereg']['headerNonAdminNavItemEventListenersAdded'] = true;

      // if isAdmin, add 'Read only' tool item (if it hasn't already been added by another Vue page)
      if (this.isAdmin) {
        if (window['qutconfig']['toolItems'].filter(function(item){return item.name && item.url == '#readonly'}).length == 0) { // if it hasn't already been added to the config by another Vue page
          window['qutconfig']['toolItems'].splice(1, 0, { name: "Read only", url: "#readonly", icon:"fas fa-eye" });
          document.getElementById('qutheadermain').dispatchEvent(new CustomEvent('qut-header-refresh'));
        }
      }

      // update tool items
      var items = document.getElementById('qutheadermain').getElementsByClassName('items')[0].getElementsByTagName('a');
      for (i=0; i < items.length; i++) {

        // modify the 'logout' link
        if (window['sitereg']['headerLogoutEventListenerAdded'] == false && items[i].href && items[i].href.indexOf('#logout') > -1) {
          
          // add default click event listener on 'logout' link so that Auth.signout is called
          items[i].addEventListener("click", function(event) {
            event.preventDefault();
            event.stopPropagation();
            window['sitereg'].mainLayoutVmAuth.signOut();
          });
          window['sitereg']['headerLogoutEventListenerAdded'] = true;
        
        } else {

          // if isAdmin, modify the 'readonly' link
          if (this.isAdmin && items[i].href && items[i].href.indexOf('#readonly') > -1) {
            
            // reset readonly label to "Read only" if this is the first run on a new Vue page
            if (this.resetReactHeaderReadonlyItemLabel) {
              items[i].getElementsByTagName("span")[0].innerText = "Read only";
              this.resetReactHeaderReadonlyItemLabel = false;
            }
            
            // add default click event listener on 'readonly' link so that Vue event is emitted
            if (window['sitereg']['headerReadonlyEventListenerAdded'] == false) {
              items[i].addEventListener("click", function(event) {
                event.preventDefault();
                event.stopPropagation();
                if (event.target.innerText != "") { // if not small screen where icon element was the target
                  event.target.innerText = event.target.innerText == "Read only" ? event.target.innerText = "Admin" : event.target.innerText = "Read only";
                }
                window['sitereg'].mainLayoutVm.$emit('readOnlyLinkClicked');
              });
              window['sitereg']['headerReadonlyEventListenerAdded'] = true;
            }
          }
        }
      }

    }
  }
}
</script>

<style lang="scss">

  /* show site title even on small screens */
  #qutheader {
    header.qut {
      div.compositelogo {
        a.site {
          display: inline-block;
        }
      }
    }
  }

  /* highlight active nav item */
  $qut-dark-blue: #012A4C;
  #qutheader {
    #qutheadermain {
      nav {
        ul {
          li {
            a {
              &.active {
                outline:none;
                background-color:white;
                color:$qut-dark-blue;
              }
            }
          }
        }
      }
    }
  }

  /* banner visible in dev environments only */
  .dev-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: orange;
    opacity: 0.97;
    z-index: 1;
  }
  .dev-banner-local {
    background-color:yellow;
  }

</style>
