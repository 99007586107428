<template>

  <main-layout :isAdmin="isAdmin" pageTitle="Areas" @readOnlyLinkClicked="isAdmin=!isAdmin">
    <alert-box :alertsMsgs="alerts"></alert-box>
    <loading :loading="load"></loading>
    <div v-if="isAdmin" class="container">

      <!-- New Area form -->
      <div class="offcanvas offcanvas-top" tabindex="-1" id="createNewAreaCanvas" aria-labelledby="createNewAreaCanvasTopLabel" ref="createNewAreaCanvas">
        <div class="offcanvas-header container">
          <h5 id="createNewAreaCanvasLabel">Add New Area</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <qut-area class="container"
            v-if="isAdmin"
            :is-admin="isAdmin"
            :persons="persons"
            @area-created="getAreas"
            @error="processError"
         >
         </qut-area>
        </div>
      </div>

      <!-- 'Add New Area' button -->
      <div class="container text-end mb-4 p-0">
        <button class="btn btn-primary" type="button" @click="showCanvas()">Add New Area</button>
      </div>
      
      <!-- Filter form -->
      <filter-form v-model:parentFilter="filter" :defaultFilterJson="defaultFilterJson" :persons="persons" filterType="areas"></filter-form>

      <!-- Result total and 'Download' button -->
      <div class="container pl-0 pb-1 mb-4">
        <div class="row">
         <div class="col-md-10">
             <!-- Paging Controls -->
             
            <pagination 
              v-model:currentPage="paging.currentPage" 
              v-model:recordsPerPage="paging.recordsPerPage" 
              v-model:currentIndex="areaFilteredCurrentIndex"
              :totalResultCount="areasFiltered.length" 
              :currentCount="areasFilteredCurrentPage.length"
              :zeroResultsMsg=true
           ></pagination>
          </div>
          <div class="col-2 mb-5">
            <download-button :items='areas' :itemsFiltered='areasFiltered' :exportFields='exportFields' :exportFilename='exportFilename'></download-button>
          </div>
        </div>
      </div>

      <!-- Areas list -->
      <qut-area class="container"
        v-for="area in areasFilteredCurrentPage"
        :key="area.id"
        :is-admin="isAdmin"
        :persons="persons"
        :initial-area="area"
        :references="references[area.id]"
        @area-updated="getAreas"
        @area-deleted="getAreas"
        @error="processError"
      >
      </qut-area>


      <pagination 
        v-model:currentPage="paging.currentPage" 
        v-model:recordsPerPage="paging.recordsPerPage" 
        v-model:currentIndex="areaFilteredCurrentIndex"
        :totalResultCount="areasFiltered.length" 
        :currentCount="areasFilteredCurrentPage.length"
        :zeroResultsMsg=false
        ></pagination>

    </div>

    <div v-else class="container">
      <table class="table">
        <thead>
          <tr>
            <th>Area</th>
            <th>Primary Contact</th>
            <th>Secondary Contact</th>
            <th>Email</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="area in areas" :key="area.id" :value="area.id">
            <td>{{ area.name }}</td>
            <td><a :href="'https://staff.qut.edu.au/staff/' + (area.primaryContact != null ? area.primaryContact.username : '')">{{area.primaryContact && area.primaryContact.firstname ? area.primaryContact.firstname + ' ' + area.primaryContact.lastname : ''}}</a></td>
            <td><a :href="'https://staff.qut.edu.au/staff/' + (area.secondaryContact != null ? area.secondaryContact.username : '')">{{area.secondaryContact && area.secondaryContact.firstname ? area.secondaryContact.firstname + ' ' + area.secondaryContact.lastname : ''}}</a></td>
            <td><a v-if="area.email" :href="'mailto:' + area.email">{{ area.email }}</a></td>
            <td><div v-if="area.type">{{ area.type.join(', ') }}</div></td>
          </tr>
        </tbody>
      </table>

    </div>

  </main-layout>

</template>

<script>

import { API, Auth } from 'aws-amplify';
import MainLayout from '../layouts/MainLayout.vue'
import QutArea from '../components/Area.vue';
import { listAreas, listPersons, listSites } from '../graphql/queries'
import AlertBox from '../components/Alert.vue';
import Loading from '../components/LoadingSpinner.vue'; 
import DownloadButton from '../components/DownloadButton.vue';
import FilterForm from '../components/FilterForm.vue';
import { sortedItems, updateQueryStringToMatchFilters, updateFiltersToMatchQueryString } from '../modules/common.js';
import { Offcanvas } from 'bootstrap';
import Pagination from '../components/Pagination.vue';
import Cache from '@aws-amplify/cache';

export default {
  components: {
    MainLayout,
    QutArea,
    AlertBox,
    Loading,
    DownloadButton,
    FilterForm,
    Pagination
  },
  beforeMount() {
    this.defaultFilterJson = JSON.stringify(this.filter);
    updateFiltersToMatchQueryString(this.filter);
  },
  async created() {
    try {
      const session = await Auth.currentSession();
      const groups = session.idToken.payload['cognito:groups'];
      if (groups.indexOf('admins') > -1) {
        this.isAdmin = true;
      }
      window.history.pushState(null, '', this.$root.currentRoute); // ensure the path in the browser address bar reflects the current route
      this.getAreas();
      this.getPersons();
      this.getSites();
    } catch(error) {
      this.processError(error);
    }
  },
  data() {
    return {
      areas: [],
      persons: [],
      sites: [],
      load: false,
      bsOffcanvas: null,
      filter: {
        name: "",
        areaPrimaryContactId: "",
        areaSecondaryContactId: "",
        areaType: ['Business', 'Tech Support'],
      },
      defaultFilterJson: "",
      isAdmin: false,
      alerts: [],
      paging: {
        currentPage: 1,
        recordsPerPage: 0 //0 to display all
      },
      areaFilteredCurrentIndex: 0,
      exportFields: ["name","type","email","primaryContact.username","primaryContact.firstname","primaryContact.lastname","secondaryContact.username","secondaryContact.firstname","secondaryContact.lastname"],
      exportFilename: "site-registry-areas"   // no extension
    }
  },
  watch: {
    filter: function() {
      this.resetCurrentPage()
    },
    areasFiltered: function() {
      updateQueryStringToMatchFilters(this.filter);
    }
  },
  computed: {
    references() {
      let siteCounts = {}
      this.areas.forEach(function(area) {
        this[area.id] = {
          allSites: 0, 
          nonDecommissionedSitesOnly: 0,
          decommissionedSitesOnly: 0
        };
      }, siteCounts);
      this.sites.forEach(function(site) {
        if (site.businessArea && site.businessArea.id && site.businessArea.id != null && site.businessArea.id != "") {
          this[site.businessArea.id].allSites += 1;
          if (site.status && site.status != null && site.status != "") {
            if (site.status.toLowerCase() != "decommissioned") {
              this[site.businessArea.id].nonDecommissionedSitesOnly += 1;
            } else {
              this[site.businessArea.id].decommissionedSitesOnly += 1;
            }
          }
        }
        if (site.techSupportArea && site.techSupportArea.id && site.techSupportArea.id != null && site.techSupportArea.id != "") {
          this[site.techSupportArea.id].allSites += 1;
          if (site.status && site.status != null && site.status != "") {
            if (site.status.toLowerCase() != "decommissioned") {
              this[site.techSupportArea.id].nonDecommissionedSitesOnly += 1;
            } else {
              this[site.techSupportArea.id].decommissionedSitesOnly += 1;
            }
          }
        }
      }, siteCounts)
      return siteCounts;
    },
    areasFiltered() {
      return this.areas.filter(function(area) {
        return true &&
          area.name.toLowerCase().indexOf(this.filter.name.toLowerCase()) > -1 &&
          (this.filter.areaPrimaryContactId == "" || this.filter.areaPrimaryContactId == null || (area.primaryContact && area.primaryContact.id == this.filter.areaPrimaryContactId)) &&
          (this.filter.areaSecondaryContactId == "" || this.filter.areaSecondaryContactId == null || (area.secondaryContact && area.secondaryContact.id == this.filter.areaSecondaryContactId)) &&
          (this.filter.areaType.length == 0 || this.areaTypeMatchFound(area.type));
      }, this);
    },
    areasFilteredCurrentPage() {
      if (this.paging.recordsPerPage == 0) return this.areasFiltered;
      return this.areasFiltered.slice(this.areaFilteredCurrentIndex, this.areaFilteredCurrentIndex + this.paging.recordsPerPage);
    },
   
  },
  methods: {
    async getAreas() {
      this.load = true;
      try {
        const areas = await API.graphql({
          query: listAreas
        });
        this.areas = areas != undefined ?  sortedItems(areas.data.listAreas.items,['name']) : [];
      } catch(error) {
        this.processError(error);
      }
      this.load = false;
    },
    async getPersons() {
      this.load = true;
      try {
        const persons = await API.graphql({
          query: listPersons,
          variables: {limit: 1000}
        });
        this.persons = persons != undefined ? sortedItems(persons.data.listPersons.items,['firstname', 'lastname']) : [];
      } catch(error) {
        this.processError(error);
      }
      this.load = false;
    },
    async getSites() {
      this.load = true;
      try {
        const sites = await API.graphql({
          query: listSites,
          variables: {
            limit: 1000,
          }
        });
  
        this.sites = sites != undefined ? sites.data.listSites.items : [];
      
      } catch(error) {
        this.processError(error,'danger');
      }
      this.load = false;
    },
    processError(error, errorType, areaName) {
      if (error == 'No current user' || error.message == 'Cannot retrieve a new session. Please authenticate.') {
        Cache.setItem('querystringBeforeAuth', location.search);  // cache filter params in querystring, since these can't be sent to Cognito
        Cache.setItem('pathBeforeAuth', location.pathname);  // cache url path so we can load the appropriate route when the user returns from auth
        Auth.federatedSignIn({provider: "QUT"});
      } else {
        if (this.bsOffcanvas && this.bsOffcanvas._isShown && errorType == "success") this.bsOffcanvas.hide();
        if (areaName != null) {
          this.filter = JSON.parse(this.defaultFilterJson);
          this.filter.areaType = [];
          this.filter.name = areaName;
        }
        this.alerts.push({
          show: true,
          message: error.message ? error.message : error,
          errorType: "alert-" + (errorType ? errorType : "danger")
        });
      }
    },
    showCanvas() {
       this.bsOffcanvas = new Offcanvas(this.$refs.createNewAreaCanvas);
       this.bsOffcanvas.show()
    },
    areaTypeMatchFound(types) {
      var found = false;
      if (types && types.length > 0) {
        for (let i=0; i<this.filter.areaType.length; i++) {
          if (types.indexOf(this.filter.areaType[i]) > -1) {
            found = true;
          }
        }
      }
      return found;
    },
    resetCurrentPage() {
      this.areaFilteredCurrentIndex = 0;
      this.paging.currentPage = 1;
    },
  }
}

</script>