import Cache from '@aws-amplify/cache';

function getToken() {
  var hash = document.location.hash;
  var tokenIdentifier = "&id_token=";
  var token = "";
  if (hash.indexOf(tokenIdentifier) > -1) {
    var tokenStart = hash.indexOf(tokenIdentifier) + tokenIdentifier.length;
    token = hash.substr(tokenStart);
    token = token.substring(0,token.indexOf("&"));
  }
  return token;
}

function cacheToken() {
  var token = getToken();
  if (token != '') {
    Cache.setItem('federatedInfo', { token: token });
    return true;
  } else {
    var cachedFederatedInfo = Cache.getItem('federatedInfo');
    if (cachedFederatedInfo && cachedFederatedInfo.token) {
      return true;
    } else {
      return false;
    }
  }
}

export { cacheToken };