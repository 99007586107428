<template>

  <main-layout :isAdmin="isAdmin" pageTitle="People" @readOnlyLinkClicked="isAdmin=!isAdmin">
    <alert-box :alertsMsgs="alerts"></alert-box>
    <loading :loading="load"></loading>
    <div v-if="isAdmin" class="container">

      <!-- New Person form -->
      <div class="offcanvas offcanvas-top" tabindex="-1" id="createNewPersonCanvas" aria-labelledby="createNewPersonCanvasTopLabel" ref="createNewPersonCanvas">
        <div class="offcanvas-header container">
          <h5 id="createNewPersonCanvasLabel">Add New Person</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <person class="container"
            v-if="isAdmin"
            :is-admin="isAdmin"
            @person-created="getPersons"
            @error="processError"
          >
          </person>
        </div>
      </div>

      <!-- 'Add New Person' button -->
      <div class="container text-end mb-4 p-0">
        <button class="btn btn-primary" type="button" @click="showCanvas()">Add New Person</button>
      </div>

      <!-- Filter form -->
      <filter-form v-model:parentFilter="filter" :defaultFilterJson="defaultFilterJson" :persons="persons" filterType="people"></filter-form>

      <!-- Result total and 'Download' button -->
      <div class="container pl-0 pb-1 mb-4">
        <div class="row">
          <div class="col-md-10">
             <!-- Paging Controls -->
            <pagination 
            v-model:currentPage="paging.currentPage" 
            v-model:recordsPerPage="paging.recordsPerPage" 
            v-model:currentIndex="peopleFilteredCurrentIndex"
            :totalResultCount="personsFiltered.length" 
            :currentCount="personsFilteredCurrentPage.length"
            :zeroResultsMsg=true
            ></pagination>
          </div>
          <div class="col-md-2 mb-3 text-end">
            <download-button :items='persons' :itemsFiltered='personsFiltered' :exportFields='exportFields' :exportFilename='exportFilename'></download-button>
          </div>
        </div>
      </div>

      <!-- Persons list -->
      <person class="container"
        v-for="person in personsFilteredCurrentPage"
        :key="person.id"
        :is-admin="isAdmin"
        :initial-person="person"
        :persons="persons"
        :areas="areas"
        :references="references[person.id]"
        @person-updated="getPersons"
        @person-deleted="getPersons"
        @error="processError"
      >
      </person>
      <div class="container pl-0 pb-1 mt-5">
       <div class="row">
          <!-- Paging Controls -->
          <pagination 
            v-model:currentPage="paging.currentPage" 
            v-model:recordsPerPage="paging.recordsPerPage" 
            v-model:currentIndex="peopleFilteredCurrentIndex"
            :totalResultCount="personsFiltered.length" 
            :currentCount="personsFilteredCurrentPage.length"
            :zeroResultsMsg=false
            ></pagination>
        </div>
      </div>
    </div>

    <div v-else class="alert alert-danger" role="alert">
      You are not authorised to view this page.
    </div>

  </main-layout>

</template>

<script>

import { API, Auth } from 'aws-amplify';
import MainLayout from '../../layouts/MainLayout.vue'
import Person from '../../components/Person.vue';
import { listPersons, listSites, listAreas } from '../../graphql/queries';
import AlertBox from '../../components/Alert.vue';
import Loading from '../../components/LoadingSpinner.vue'; 
import DownloadButton from '../../components/DownloadButton.vue';
import FilterForm from '../../components/FilterForm.vue';
import { sortedItems, updateQueryStringToMatchFilters, updateFiltersToMatchQueryString } from '../../modules/common.js';
import { Offcanvas } from 'bootstrap';
import Pagination from '../../components/Pagination.vue';
import Cache from '@aws-amplify/cache';

export default {
  components: {
    MainLayout,
    Person,
    AlertBox,
    Loading,
    DownloadButton,
    FilterForm,
    Pagination
  },
  beforeMount() {
    this.defaultFilterJson = JSON.stringify(this.filter);
    updateFiltersToMatchQueryString(this.filter);
  },
  async created() {
    try {
      const session = await Auth.currentSession();
      const groups = session.idToken.payload['cognito:groups'];
      if (groups.indexOf('admins') > -1) {
        this.isAdmin = true;
      }
      window.history.pushState(null, '', this.$root.currentRoute); // ensure the path in the browser address bar reflects the current route
      this.getPersons();
      this.getSites();
      this.getAreas();
    } catch(error) {
      this.processError(error);
    }
  },
  data() {
    return {
      persons: [],
      sites: [],
      areas: [],
      filter: {
        searchText: "",
        references: ""
      },
      defaultFilterJson: "",
      isAdmin: false,
      alerts: [],
      load: false,
      bsOffcanvas: null,
      exportFields: ["username", "firstname", "lastname"],
      exportFilename: "site-registry-people",  // no extension
      paging: {
        currentPage: 1,
        recordsPerPage: 100 //0 to display all
      },
      peopleFilteredCurrentIndex: 0,
    }
  },
  watch: {
    filter: function() {
      this.resetCurrentPage()
     },
    personsFiltered: function() {
      updateQueryStringToMatchFilters(this.filter);
    }
  },
  computed: {
    references() {
      let counts = {}
      this.persons.forEach(function(person) {
        this[person.id] = {
          sites: {
            allSites: 0, 
            nonDecommissionedSitesOnly: 0,
            decommissionedSitesOnly: 0
          },
          areas: {
            allAreas: 0,
            primaryContact: 0,
            secondaryContact: 0
          }
        };
      }, counts);
      this.sites.forEach(function(site) {
        if (site.businessContact && site.businessContact.id && site.businessContact.id != null && site.businessContact.id != "") {
          this[site.businessContact.id].sites.allSites += 1;
          if (site.status && site.status != null && site.status != "") {
            if (site.status.toLowerCase() != "decommissioned") {
              this[site.businessContact.id].sites.nonDecommissionedSitesOnly += 1;
            } else {
              this[site.businessContact.id].sites.decommissionedSitesOnly += 1;
            }
          }
        }
        if (site.techSupportContact && site.techSupportContact.id && site.techSupportContact.id != null && site.techSupportContact.id != "") {
          this[site.techSupportContact.id].sites.allSites += 1;
          if (site.status && site.status != null && site.status != "") {
            if (site.status.toLowerCase() != "decommissioned") {
              this[site.techSupportContact.id].sites.nonDecommissionedSitesOnly += 1;
            } else {
              this[site.techSupportContact.id].sites.decommissionedSitesOnly += 1;
            }
          }
        }
      }, counts);
      this.areas.forEach(function(area) {
        if (area.primaryContact && area.primaryContact.id && area.primaryContact.id != null && area.primaryContact.id != "") {
          this[area.primaryContact.id].areas.allAreas += 1;
          this[area.primaryContact.id].areas.primaryContact += 1;
        }
        if (area.secondaryContact && area.secondaryContact.id && area.secondaryContact.id != null && area.secondaryContact.id != "") {
          this[area.secondaryContact.id].areas.allAreas += 1;
          this[area.secondaryContact.id].areas.secondaryContact += 1;
        }
      }, counts);
      return counts;
    },
    personsFiltered() {
      return this.persons.filter(function(person) {
        return true &&
          (this.filter.searchText == '' || person.username.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) > -1 || (person.firstname && person.firstname.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) > -1) || (person.lastname && person.lastname.toLowerCase().indexOf(this.filter.searchText.toLowerCase()) > -1) || (person.firstname && person.lastname && (person.firstname.toLowerCase() + ' ' + person.lastname.toLowerCase()).indexOf(this.filter.searchText.toLowerCase()) > -1)) &&
          (this.filter.references == '' || (this.filter.references == 'HasNoRefs' && this.references[person.id].sites.allSites + this.references[person.id].areas.allAreas == 0) || (this.filter.references == 'HasNoCurrentSiteRefs' && this.references[person.id].sites.nonDecommissionedSitesOnly == 0) || (this.filter.references == 'HasCurrentSiteRefs' && this.references[person.id].sites.nonDecommissionedSitesOnly > 0) || (this.filter.references == 'HasAreaRefs' && this.references[person.id].areas.allAreas > 0));
      }, this);
    },
  
    personsFilteredCurrentPage() {
      if (this.paging.recordsPerPage == 0) return this.personsFiltered;
      return this.personsFiltered.slice(this.peopleFilteredCurrentIndex, this.peopleFilteredCurrentIndex + this.paging.recordsPerPage);
    },
    
  },
  methods: {
    async getPersons() {
      this.load = true;
      try {
        const persons = await API.graphql({
          query: listPersons,
          variables: {
            limit: 1000
          }
        });
        this.persons = persons != undefined ? sortedItems(persons.data.listPersons.items,['username']): [];
         
      } catch(error) {
        this.processError(error);
      }
      this.load = false;
    },
    async getSites() {
      this.load = true
      try {
        const sites = await API.graphql({
          query: listSites,
          variables: {
            limit: 1000,
          }
        });
  
        this.sites = sites != undefined ? sites.data.listSites.items : [];
      
      } catch(error) {
        this.processError(error,'danger');
      }
      this.load = false
    },
    async getAreas() {
      try {
        const areas = await API.graphql({
          query: listAreas
        });
        this.areas = areas != undefined ?  areas.data.listAreas.items : [];
      } catch(error) {
         this.processError(error,'danger');
      }
    },
    processError(error, errorType, fullname) {
      if (error == 'No current user' || error.message == 'Cannot retrieve a new session. Please authenticate.') {
        Cache.setItem('querystringBeforeAuth', location.search);  // cache filter params in querystring, since these can't be sent to Cognito
        Cache.setItem('pathBeforeAuth', location.pathname);  // cache url path so we can load the appropriate route when the user returns from auth
        Auth.federatedSignIn({provider: "QUT"});
      } else {
        if (this.bsOffcanvas && this.bsOffcanvas._isShown && errorType == "success") this.bsOffcanvas.hide();
        if (fullname != null) {
          this.filter = JSON.parse(this.defaultFilterJson);
          this.filter.searchText = fullname;
        }
        this.alerts.push({
          show: true,
          message: error.message ? error.message : error,
          errorType: "alert-" + (errorType ? errorType : "danger")
        });
      }
    },
    showCanvas() {
       this.bsOffcanvas = new Offcanvas(this.$refs.createNewPersonCanvas);
       this.bsOffcanvas.show()
    },
    resetCurrentPage() {
      this.peopleFilteredCurrentIndex = 0;
      this.paging.currentPage = 1;
    },
  }
}

</script>