<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-4 mb-lg-0 mb-sm-3 mb-2 form-group">
        <label :for="'areaName' + area.id">Area Name</label>
        <input type="text" v-model="area.name" class="form-control form-control-sm" :id="'areaName' + area.id" :class="{'is-invalid': isValidating && area.name == ''}">
        <div v-if="isValidating && area.name==''" class="invalid-feedback" style="display:block">
          Please provide an area name.
        </div>
      </div>
      <div class="col-lg-2 col-md-4 mb-lg-0 mb-sm-3 mb-2 form-group">
        <label title="Untick all types to 'archive' an area and prevent it from being used by new site records" class="help">Area Type</label>
        <div v-for="areaType in areaTypes" :key="areaType">
          <input type="checkbox" v-model="area.type" :value="areaType" :id="'areaType' + areaType + area.id"> <label :for="'areaType' + areaType + area.id">{{ areaType }}</label>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mb-lg-0 mb-sm-3 mb-2 form-group">
        <label :for="'areaEmail' + area.id">Email</label>
        <input type="text" v-model="area.email" class="form-control form-control-sm" :id="'areaEmail' + area.id">
      </div>
      <div class="col-lg-2 col-md-6 mb-lg-0 mb-sm-3 mb-2 form-group">
        <label :for="'areaPrimaryContactId' + area.id">Primary Contact</label>
        <select v-model="area.areaPrimaryContactId" class="form-control form-control-sm" :id="'areaPrimaryContactId' + area.id">
          <option value=""></option>
          <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-6 mb-lg-0 mb-sm-3 mb-2 form-group">
        <label :for="'areaSecondaryContactId' + area.id">Secondary Contact</label>
        <select v-model="area.areaSecondaryContactId" class="form-control form-control-sm" :id="'areaSecondaryContactId' + area.id">
          <option value=""></option>
          <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
        </select>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-3 col-6 mb-5">
        <span v-if="area.id">
          <label>Actions</label><br>
          <button @click="updateArea" class="btn btn-primary btn-sm me-2 mb-1">Update</button>
          <button @click="deleteArea" class="btn btn-danger btn-sm mb-1" :disabled="deleteDisabled" :aria-disabled="deleteDisabled" :class="{'pe-auto': deleteDisabled}" :style="deleteDisabled ? 'cursor:default' : null" :title="deleteDisabled ? 'Currently in use. Cannot delete.' : ''">Delete</button>
        </span>
        <span class="mt-4" v-else>
          <button @click="createArea" class="btn btn-primary btn-sm me-2">Create Area</button>
          <button @click="resetFields" class="btn btn-light btn-sm">Reset</button>
        </span>
      </div>
      <div v-if="area.id" class="col-md-9 col-6 text-end">
          References<br>
          <span class="small"><component :is="references.nonDecommissionedSitesOnly > 0 ? 'v-link' : 'span'" :href="currentSiteReferencesHref"><abbr title="Live, Development or Dead">Current</abbr> sites</component> <span class="badge bg-secondary">{{ references.nonDecommissionedSitesOnly }}</span></span><br>
          <span class="small"><component :is="references.decommissionedSitesOnly > 0 ? 'v-link' : 'span'" :href="decommSiteReferencesHref">Decom sites</component> <span class="badge bg-secondary">{{ references.decommissionedSitesOnly }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import { createArea, updateArea, deleteArea } from '../graphql/mutations';
import VLink from '../components/VLink.vue';
import { areaTypes } from '../modules/common.js'

export default {
  emits: ['error', 'areaCreated', 'areaUpdated', 'areaDeleted'],
  components: {
    VLink
  },
  props: {
    initialArea: {
      default: function() {
        return {}
      },
      type: Object,
    },
    persons: {
      default: function() {
        return []
      },
      type: Array
    },
    isAdmin: {
      default: false,
      type: Boolean
    },
    references: {
      default: function() {
        return {}
      },
      type: Object
    },
  },
  async created() {
    this.originalAreaData = JSON.stringify(this.area);
  },
  data() {
    return {
      isValidating: false,
      area: {
        id: this.initialArea.id ? this.initialArea.id : null,
        name: this.initialArea.name ? this.initialArea.name : "",
        type: this.initialArea.type ? this.initialArea.type : [],
        email: this.initialArea.email ? this.initialArea.email : "",
        areaPrimaryContactId: this.initialArea.primaryContact ? this.initialArea.primaryContact.id : null,
        areaSecondaryContactId: this.initialArea.secondaryContact ? this.initialArea.secondaryContact.id : null,
      },
      areaTypes: areaTypes,
    }
  },
  computed: {
    deleteDisabled() {
      return this.references.allSites > 0;
    },
    currentSiteReferencesHref() {
      return this.references.nonDecommissionedSitesOnly == 0 ? undefined : "/?statuses=live,dead,development&searchText=" + this.area.name;
    },
    decommSiteReferencesHref() {
      return this.references.decommissionedSitesOnly == 0 ? undefined : "/?statuses=decommissioned&searchText=" + this.area.name;
    }
  },
  methods: {
    async createArea() {
      this.isValidating = true;
      if (!this.area || !this.area.name) return;
      try {
        await API.graphql({
          query: createArea,
          variables: {input: this.area},
        });
        this.isValidating = false;
        this.$emit('areaCreated');
        this.$emit("error", "'" + this.area.name + "' added", "success", this.area.name);
        this.area.name = "";
        this.area.areaPrimaryContactId = null;
        this.area.areaSecondaryContactId = null;
      } catch(error) {
         this.$emit('error', error);
      }
    },
    async updateArea() {
      this.isValidating = true;
      if (!this.area || !this.area.id || !this.area.name) return;
      try {
        await API.graphql({
          query: updateArea,
          variables: {input: this.area},
        });
        this.$emit('areaUpdated');
        this.$emit("error", "'" + this.area.name + "' updated", "success");
      } catch(error) {
         this.$emit('error', error);
      }
    },
    async deleteArea() {
      if (!this.area || !this.area.id) return;
      if (confirm("Are you sure you wish to delete '" + this.area.name + "'?")) {
        try {
          const input = {id: this.area.id};
          await API.graphql({
            query: deleteArea,
            variables: {input: input},
          });
          this.$emit('areaDeleted');
          this.$emit("error", "'" + this.area.name + "' deleted", "warning");
        } catch(error) {
          this.$emit('error', error);
        }
      }
    },
    async resetFields() {
      this.area = JSON.parse(this.originalAreaData);
    },
  }
};
</script>