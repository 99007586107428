import { createApp, h } from 'vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import { cacheToken } from './modules/auth-helpers.js';
import routes from './routes';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import Cache from '@aws-amplify/cache';

const SimpleRouterApp = {
  created() {
    let cachedPath = Cache.getItem('pathBeforeAuth');
    if (cachedPath) {
      Cache.removeItem('pathBeforeAuth');
      this.currentRoute = cachedPath;
    }
  },
  data() {
    return {
      currentRoute: window.location.pathname
    }
  },
  computed: {
    CurrentComponent() {
      const matchingPage = routes[this.currentRoute.split('?')[0]] || '404Page';  // don't include querystring when looking for a match (<v-link> links will include the querystring)
      return require(`./pages/${matchingPage}.vue`).default;
    }
  },
  render() {
    return h(this.CurrentComponent);
  }
}

// auth settings
aws_exports.oauth.scope = [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
];
aws_exports.oauth.redirectSignIn = window.location.origin + '/';
if (window.location.origin == "https://site-registry.qut.edu.au") {
  aws_exports.oauth.redirectSignOut = 'https://esoe.qut.edu.au/auth/realms/qut/protocol/openid-connect/logout?redirect_uri=' + encodeURIComponent(window.location.origin);
} else {
  aws_exports.oauth.redirectSignOut = 'https://esoe-tst.qut.edu.au/auth/realms/QUT/protocol/openid-connect/logout?redirect_uri=' + encodeURIComponent(window.location.origin);
}
aws_exports.oauth.responseType = "token";  // or 'code', note that REFRESH token will only be generated when the responseType is code

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

// check if backend environment is configured for federated sign in (dev and prod environments should be true, feature branch environments should be false)
const useFederatedSignIn = Boolean(aws_exports.oauth && aws_exports.oauth.domain);

if (useFederatedSignIn) {  
  cacheToken();
}

window['sitereg']['rootAppVm'] = createApp(SimpleRouterApp).mount('#app');
