/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      name
      url
      isHttps
      aliases
      status
      type
      notes
      reviewDate
      decommissionDate
      createdAt
      updatedAt
      techSupportArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      businessArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      techSupportContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      businessContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        isHttps
        aliases
        status
        type
        notes
        reviewDate
        decommissionDate
        createdAt
        updatedAt
        techSupportArea {
          id
          name
          type
          email
          createdAt
          updatedAt
        }
        businessArea {
          id
          name
          type
          email
          createdAt
          updatedAt
        }
        techSupportContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        businessContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      name
      type
      email
      createdAt
      updatedAt
      primaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      secondaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      username
      firstname
      lastname
      createdAt
      updatedAt
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
