<template>
  <div class="dropdown float-end">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="downloadMenu" data-bs-toggle="dropdown" aria-expanded="false">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16" alt="">
      <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
      <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
      </svg> Download
    </button>
    <ul class="dropdown-menu" aria-labelledby="downloadMenu">
      <li><button class="dropdown-item" @click="exportItems(items, 'csv')">CSV - all</button></li>
      <li><button class="dropdown-item" @click="exportItems(itemsFiltered, 'csv')">CSV - filtered</button></li>
      <li><button class="dropdown-item" @click="exportItems(items, 'json')">JSON - all</button></li>
      <li><button class="dropdown-item" @click="exportItems(itemsFiltered, 'json')">JSON - filtered</button></li>
    </ul>
  </div>
</template>

<script>
  import { saveAs } from "file-saver";
  export default {
    props: {
      items: Array,
      itemsFiltered: Array,
      exportFields: Array,
      exportFilename: String
    },
    methods: {
      exportItems(itemsToExport, format) {
        if (format == "csv") {
        this.exportArrayOfObjectsToCSV(itemsToExport, this.exportFields, this.exportFilename);
        } else {
        this.exportObjectAsJSON(itemsToExport, this.exportFilename);
        }
      },
      exportArrayOfObjectsToCSV(objects, fieldsToExport, filename) {
        let csv = "";
        // generate column headings
        csv += `"${fieldsToExport.join("\",\"")}"\n`;
        // generate rows
        objects.forEach(function(obj) {
          for (let i=0; i<fieldsToExport.length; i++) {
            let field;
            // check if field represents a top-level obj property or a sub-object property
            if (fieldsToExport[i].indexOf(".") > -1) {   // assumes no more than 1 dot will be present
              let props = fieldsToExport[i].split(".");
              if (obj[props[0]] != undefined && obj[props[0]][props[1]] != undefined) {
                field = obj[props[0]][props[1]];
              } else {
                field = undefined;
              }
            } else {
              field = obj[fieldsToExport[i]];
            }
            // add delimiter (unless this is the first field of the row)
            if (i > 0) {
              csv += `,`;
            }
            // add the value
            if (field == undefined) {
              csv += `""`;
            } else {
              if (Array.isArray(field)) {
                csv += `"${field.join(", ").replace(/"/g,"'")}"`;  // line breaks and commas are acceptable in a field in RFC 4180 if inside double quotes, so these don't need to be replaced
              } else {
                if (typeof(field) == "boolean") {
                  csv += `"${field}"`;
                } else {
                  csv += `"${field.replace(/"/g,"'")}"`;  // line breaks and commas are acceptable in a field in RFC 4180 if inside double quotes, so these don't need to be replaced
                }
              }
            }
          }
          csv += `\r\n`; // RFC 4180 requires CR LF line endings
        });
        csv = "\ufeff" + csv; //BOM for utf-8 encoding
        let blob = new Blob([csv], {
          type: "application/csv"
        });
        saveAs(blob, filename + '.csv');
      },
      exportObjectAsJSON(object, filename) {
        let blob = new Blob([JSON.stringify(object)], {
          type: "application/json"
        });
        saveAs(blob, filename + '.json');
      }
    }
  }
</script>