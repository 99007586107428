<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-3 form-group">
        <label :for="'personUsername' + person.id">Username</label>
        <input type="text" v-model="person.username" class="form-control" :id="'personUsername' + person.id" :class="{'is-invalid': isValidating && person.username == ''}">
        <div v-if="isValidating && person.username==''" class="invalid-feedback" style="display:block">
          Please provide a username.
        </div>
      </div>
      <div class="col-md-2 form-group">
        <label :for="'personFirstname' + person.id">Firstname</label>
        <input type="text" v-model="person.firstname" class="form-control" :id="'personFirstname' + person.id">
      </div>
      <div class="col-md-2 form-group">
        <label :for="'personLastname' + person.id">Lastname</label>
        <input type="text" v-model="person.lastname" class="form-control" :id="'personLastname' + person.id">
      </div>
      <div v-if="person.id" class="col-md-5">
        <div class="row">
          <div class="col-5">
            <label>Actions</label><br>
            <button @click="updateperson" class="btn btn-primary me-2 mb-1">Update</button>
            <button @click="deleteperson" class="btn btn-danger mb-1" :disabled="deleteDisabled" :aria-disabled="deleteDisabled" :class="{'pe-auto': deleteDisabled}" :style="deleteDisabled ? 'cursor:default' : null" :title="deleteDisabled ? 'Currently in use. Cannot delete.' : ''">Delete</button>
          </div>
          <div class="col-7 text-md-start text-end">
            References<br>
            <div class="row">
              <div class="col-xl-6">
                <span class="small"><component :is="references.sites.nonDecommissionedSitesOnly > 0 ? 'v-link' : 'span'" :href="currentSiteReferencesHref"><abbr title="Live, Development or Dead">Current</abbr> sites</component> <span class="badge bg-secondary">{{ references.sites.nonDecommissionedSitesOnly }}</span></span><br>
                <span class="small"><component :is="references.sites.decommissionedSitesOnly > 0 ? 'v-link' : 'span'" :href="decommSiteReferencesHref">Decom sites</component> <span class="badge bg-secondary">{{ references.sites.decommissionedSitesOnly }}</span></span><br>                
              </div>
              <div class="col-xl-6">
                <span class="small"><component :is="references.areas.allAreas > 0 ? 'v-link' : 'span'" :href="areaReferencesHref">Areas</component> <span class="badge bg-secondary">{{ references.areas.allAreas }}</span></span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="col-md-4 mt-4">
          <button @click="createperson" class="btn btn-primary me-2">Create person</button>
          <button @click="resetFields" class="btn btn-light">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import { createPerson, updatePerson, deletePerson } from '../graphql/mutations';
import VLink from '../components/VLink.vue';

export default {
  emits: ['error', 'personCreated', 'personUpdated', 'personDeleted'],
  components: {
    VLink
  },
  props: {
    initialPerson: {
      default: function() {
        return {}
      },
      type: Object,
    },
    isAdmin: {
      default: false,
      type: Boolean
    },
    references: {
      default: function() {
        return {}
      },
      type: Object
    },
    persons: {
      default: function() {
        return []
      },
      type: Array
    },
    areas: {
      default: function() {
        return []
      },
      type: Array
    }
  },
  async created() {
    this.originalPersonData = JSON.stringify(this.person);
  },
  data() {
    return {
      isValidating: false,
      showReferences: false,
      person: {
        id: this.initialPerson.id  ? this.initialPerson.id : null,
        username: this.initialPerson.username ? this.initialPerson.username : "",
        firstname: this.initialPerson.firstname ? this.initialPerson.firstname : "",
        lastname: this.initialPerson.lastname ? this.initialPerson.lastname : "",
      }
    }
  },
  computed: {
    name() {
      return this.person.firstname + ' ' + this.person.lastname;
    },
    deleteDisabled() {
      return this.references.sites.allSites + this.references.areas.allAreas > 0;
    },
    currentSiteReferencesHref() {
      return this.references.sites.nonDecommissionedSitesOnly == 0 ? undefined : "/?statuses=live,dead,development&searchText=" + this.person.username;
    },
    decommSiteReferencesHref() {
      return this.references.sites.decommissionedSitesOnly == 0 ? undefined : "/?statuses=decommissioned&searchText=" + this.person.username;
    },
    areaReferencesHref() {
      return this.references.areas.allAreas == 0 ? undefined : '/areas';
    }
  },
  methods: {
    async createperson() {
      this.isValidating = true;
      if (!this.person || !this.person.username) return;
      try {
        await API.graphql({
          query: createPerson,
          variables: {input: this.person},
        });
        this.isValidating = false;
        this.$emit("error", "'" + this.name + "' added", "success", this.name);
        this.$emit('personCreated');
        this.person.username = "";
        this.person.firstname = "";
        this.person.lastname = "";
        
      } catch(error) {
          this.$emit('error', error);
      }
    },
    async updateperson() {
      this.isValidating = true;
      if (!this.person || !this.person.id || !this.person.username) return;
      try {
        await API.graphql({
          query: updatePerson,
          variables: {input: this.person},
        });
        this.$emit("error", "'" + this.name + "' updated", "success");
        this.$emit('personUpdated');
      } catch(error) {
          this.$emit('error', error);
      }
    },
    async deleteperson() {
      if (!this.person || !this.person.id) return;
      if (confirm("Are you sure you wish to delete '" + this.name + "'?")) {
        try {
          const input = {id: this.person.id};
          await API.graphql({
            query: deletePerson,
            variables: {input: input},
          });
          
          this.$emit('personDeleted');
          this.$emit('error', "'" + this.name + "' deleted", "warning");
        } catch(error) {
          this.$emit('error', error);
        }
      }
    },
    async resetFields() {
      this.person = JSON.parse(this.originalPersonData);
    },
  }
};
</script>