<template>
  <a
    :href="href"
    @click.prevent="go"
  >
    <slot></slot>
  </a>
</template>

<script>
import routes from '../routes'
export default {
  props: {
    href: {
      type:String,
      required: true
    }
  },
  computed: {
    pathname() {
      if (this.href.indexOf('?') > -1 ) {
        return this.href.substring(0, this.href.indexOf('?'));
      } else {
        return this.href;
      }
    }
  },
  methods: {
    go () {
      this.$root.currentRoute = this.pathname;
      window.history.pushState(null, routes[this.href], this.href)
    }
  }
}
</script>