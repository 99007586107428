<template>
  <main-layout :isAdmin="isAdmin" @readOnlyLinkClicked="isAdmin=!isAdmin">
    <div id="app">
      <alert-box :alertsMsgs="alerts"></alert-box>
      <loading :loading="load"></loading>
      <!-- Site Creation Form -->
      <div class="offcanvas offcanvas-top" tabindex="-1" id="createNewSiteCanvas" aria-labelledby="createNewSiteCanvasLabel" ref="createNewSiteCanvas">
        <div class="offcanvas-header container">
          <h5 id="createNewSiteCanvasLabel">Add New Site</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <site
              v-if="isAdmin"
              :is-admin="isAdmin"
              :areas="areas"
              :persons="persons"
              @site-created="getSites"
              @error="processError"
            ></site>
        </div>
      </div>

      <!-- 'Add new site' button -->
      <div v-if="isAdmin" class="container text-end mb-4 p-0">
        <button class="btn btn-primary" type="button" @click="showCanvas()">Add New Site</button>
      </div>

      <!-- Filter form -->
      <filter-form
        v-model:parentFilter="filter"
        :defaultFilterJson="defaultFilterJson"
        :persons="persons"
        :areas="areas"
        :dateFormat="dateFormat"
        filterType="sites"
      ></filter-form>
      <!-- Result total, paging controls and 'Download' button -->
      <div class="container pl-0 pb-1 mb-4">
        <div class="row">
          <div class="col-md-10">
             <!-- Paging Controls -->
            <pagination 
            v-model:currentPage="paging.currentPage" 
            v-model:recordsPerPage="paging.recordsPerPage" 
            v-model:currentIndex="sitesFilteredCurrentIndex"
            :totalResultCount="sitesFiltered.length" 
            :currentCount="sitesFilteredCurrentPage.length"
            :zeroResultsMsg=true
            ></pagination>
          </div>
          <div class="col-md-2 mb-3 text-end">
            <download-button :items='sites' :itemsFiltered='sitesFiltered' :exportFields='exportFields' :exportFilename='exportFilename'></download-button>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul class="list-inline small float-end help m-2">
              <li class="list-inline-item p-1" v-for="status in Object.keys(siteStatuses)" :key="status" :class="'text-' + siteStatuses[status].bsColour" :title="siteStatuses[status].description" >&#9679; {{ status }}</li> 
            </ul>
          </div>
        </div>
      </div>
      
      <!-- Site List -->
      
      <site
        v-for="site in sitesFilteredCurrentPage"
        :key="site.id"
        :is-admin="isAdmin"
        :areas="areas"
        :persons="persons"
        :initial-site="site"
        @site-updated="getSites"
        @site-deleted="getSites"
        @error="processError"        
      ></site>
      
    </div>
    <div class="container pl-0 pb-1 mt-5">
      <div class="row">
     
        <pagination 
          v-model:currentPage="paging.currentPage" 
          v-model:recordsPerPage="paging.recordsPerPage" 
          v-model:currentIndex="sitesFilteredCurrentIndex"
          :totalResultCount="sitesFiltered.length" 
          :currentCount="sitesFilteredCurrentPage.length"
          :zeroResultsMsg=false
          ></pagination>

      </div>
    </div>
  </main-layout>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { listSites, listAreas, listPersons } from '../graphql/queries';
import MainLayout from '../layouts/MainLayout.vue'
import Site from '../components/Site.vue';
import AlertBox from '../components/Alert.vue';
import Loading from '../components/LoadingSpinner.vue'; 
import Pagination from '../components/Pagination.vue';
import DownloadButton from '../components/DownloadButton.vue';
import FilterForm from '../components/FilterForm.vue';
import { sortedItems, siteStatuses, updateQueryStringToMatchFilters, updateFiltersToMatchQueryString } from '../modules/common.js';
import Cache from '@aws-amplify/cache';
import { Offcanvas } from 'bootstrap'

export default {
  components: {
    Loading,
    MainLayout,
    Site,
    AlertBox,
    DownloadButton,
    FilterForm,
    Pagination
  },

  beforeMount() {
    this.defaultFilterJson = JSON.stringify(this.filter);
    updateFiltersToMatchQueryString(this.filter);
  },

  async created() {
    try {
      const session = await Auth.currentSession();
      const groups = session.idToken.payload['cognito:groups'];
      if (groups.indexOf('admins') > -1) {
        this.isAdmin = true;
      }
      window.history.pushState(null, '', this.$root.currentRoute); // ensure the path in the browser address bar reflects the current route
      this.getSites();
      this.getAreas();
      this.getPersons();
    } catch (error) {
      this.processError(error,'danger');
    }
    
  },
  props: {
    dateFormat: {
      default: "dd-MM-yyyy",
      type: String
    },
    
  },
  data() {
    return {
      sites: [],
      areas: [],
      persons: [],
      isAdmin: false,
      alerts: [],
      load: true,
      bsOffcanvas: false,
      sitesFilteredCurrentIndex: 0,
      filter: {
        searchText: "",
        statuses: ["live", "development"],
        types: [],
        siteAreaId: "",
        sortBy: "name",
        decommissionDateYear: "",
        decommissionDateMonth: "",
        orderBy: "asc",
        dueForReview: "",
      },
      defaultFilterJson: "",
      paging: {
        currentPage: 1,
        recordsPerPage: 50 //0 to display all
      },
      exportFields: ["name","url","isHttps","aliases","status","type","businessArea.name","businessArea.type","businessArea.email","businessContact.username","businessContact.firstname","businessContact.lastname","techSupportArea.name","techSupportArea.type","techSupportArea.email","techSupportContact.username","techSupportContact.firstname","techSupportContact.lastname","notes","reviewDate","decommissionDate"],
      exportFilename: "site-registry-sites"   // no extension
    }
  },

  computed: {
    sitesFiltered() {
      return sortedItems(this.sites.filter(this.siteFilter, this),[this.filter.sortBy],this.filter.orderBy);
    },
    
    sitesFilteredCurrentPage() {
      if (this.paging.recordsPerPage == 0) return this.sitesFiltered;
      return this.sitesFiltered.slice(this.sitesFilteredCurrentIndex, this.sitesFilteredCurrentIndex + this.paging.recordsPerPage);
    },

    siteStatuses() {
      return siteStatuses;
    },

    
  
  },

  watch: {
    sitesFiltered: function() {
      updateQueryStringToMatchFilters(this.filter);
    },
    filter: function() {
      this.resetCurrentPage();
    }
    
  },
  
  methods: {
    siteFilter(site) {  
      return true &&
          (this.filter.searchText == '' || this.searchMatchFound(this.filter.searchText, site) ) &&
          (this.filter.statuses.length == 0 || (site.status && site.status != null && this.filter.statuses.indexOf(site.status.toLowerCase()) > -1)) && 
          (this.filter.types.length == 0 || (site.type && site.type != null && this.filter.types.indexOf(site.type.toLowerCase()) > -1)) &&
          (this.filter.siteAreaId == "" || (site.businessArea && site.businessArea.id && site.businessArea.id == this.filter.siteAreaId) || (site.techSupportArea && site.techSupportArea.id && site.techSupportArea.id == this.filter.siteAreaId)) &&
          (this.filter.decommissionDateYear == "" || this.decommissionDateMatchFound(this.filter.decommissionDateYear, this.filter.decommissionDateMonth, site.decommissionDate) ) &&
          (this.filter.dueForReview == '' || this.reviewDateMatchFound(this.filter.dueForReview, site.reviewDate) )  
    },
    

    resetCurrentPage() {
      this.sitesFilteredCurrentIndex = 0;
      this.paging.currentPage = 1;
    },

    async getSites() {
      this.load = true
      try {
        const sites = await API.graphql({
          query: listSites,
          variables: {
            limit: 1000,
          }
        });
  
        this.sites = sites != undefined ? sites.data.listSites.items : [];
      
      } catch(error) {
        this.processError(error,'danger');
      }
      this.load = false
    },

    async getAreas() {
      try {
        const areas = await API.graphql({
          query: listAreas
        });
        this.areas = areas != undefined ?  sortedItems(areas.data.listAreas.items,['name'],'asc') : [];
      } catch(error) {
         this.processError(error,'danger');
      }
    },

    async getPersons() {
      try {
        const persons = await API.graphql({
          query: listPersons,
          variables: {
            limit: 1000,
          }
        });
        this.persons = persons != undefined ? sortedItems(persons.data.listPersons.items,['firstname', 'lastname'],'asc') : [];
      } catch(error) {
         this.processError(error,'danger');
      }
    },

    searchMatchFound(value, object) {
      const propsToCheck = ["name", "url", "aliases", "status", "types", "notes", "username", "firstname", "lastname"];
      for (const prop in object) {
        
        if (typeof object[prop] === 'object' && object[prop] !== null && !Array.isArray(object[prop])) {
          if (this.searchMatchFound(value, object[prop])) {
            return true;
          }
        } else {
          if (propsToCheck.indexOf(prop) > -1) {
           
            if (Array.isArray(object[prop])) {
              if (object[prop] != null && object[prop].join().toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return true;
              }
            } else {
              if (object[prop] != undefined && object[prop].toString().toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },

    reviewDateMatchFound(filterValue, reviewDateString) {
      let reviewDateObj;
      if (reviewDateString != undefined && reviewDateString != null && reviewDateString != '') {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        reviewDateObj = new Date(reviewDateString + 'T00:00:00+10:00');
        switch(filterValue) {
          case 'now':
            return reviewDateObj <= today;
          case 'nextweek':
            return reviewDateObj <= today.setDate(today.getDate() + 7);
          case 'nextmonth':
            return reviewDateObj <= today.setMonth(today.getMonth() + 1);
          case 'next6months':
            return reviewDateObj <= today.setMonth(today.getMonth() + 6);
          default:
            return false;
        }
      }
      return false;
    },

    decommissionDateMatchFound(filterValueYear, filterValueMonth, decommissionDateString) {
      let decommissionDateObj;
      if (decommissionDateString != undefined && decommissionDateString != null && decommissionDateString != '') {
        decommissionDateObj = new Date(decommissionDateString + 'T00:00:00+10:00');
        if (filterValueMonth != null && filterValueMonth !== "") {
          if ((decommissionDateObj.getFullYear().toString() == filterValueYear) && (decommissionDateObj.getMonth() == filterValueMonth)) {
            return true;
          } else {
            return false;
          }
        } else {
          return decommissionDateObj.getFullYear().toString() == filterValueYear;
        }
      }
      return false;
    },

    processError(error, errorType, url) {
      if (error == 'No current user' || error.message == 'Cannot retrieve a new session. Please authenticate.') {
        Cache.setItem('querystringBeforeAuth', location.search);  // cache filter params in querystring, since these can't be sent to Cognito
        Cache.setItem('pathBeforeAuth', location.pathname);  // cache url path so we can load the appropriate route when the user returns from auth
        Auth.federatedSignIn({provider: "QUT"});
      } else {
        if (this.bsOffcanvas && this.bsOffcanvas._isShown && errorType == "success") this.bsOffcanvas.hide();
        if (url != null) {
          this.filter = JSON.parse(this.defaultFilterJson);
          this.filter.statuses = [];
          this.filter.searchText = url;
        }

        this.alerts.push({
          show: true,
          message: error.message ? error.message : error,
          errorType: "alert-" + (errorType ? errorType : "danger")
        });
      }
    },

    showCanvas() {
       this.bsOffcanvas = new Offcanvas(this.$refs.createNewSiteCanvas);
       this.bsOffcanvas.show();
    },
    
  }
}
</script>
<style lang="scss">
  #createNewSiteCanvas.offcanvas-top {
    height: 100% !important;
  }
  #sortBy.form-control {
    padding-left: 0.5rem;
  }
</style>