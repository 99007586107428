<template>
  <main-layout :isAdmin="isAdmin">
    <div class="container">
   
      <alert-box :alertsMsgs="alerts"></alert-box>

      <h1>About the QUT Site Registry</h1>

      <p>The site registry is a database of all QUT websites, maintained by Digital Business Solutions.</p>

      <p>On this page</p>
      <ul>
        <li><a href="#h2-0">QUT-wide</a></li>
        <li><a href="#h2-1">What goes into the site registry?</a></li>
        <li><a href="#h2-2">Fields in the site registry</a></li>
        <li><a href="#h2-3">Who to contact about a site</a></li>
        <li><a href="#h2-4">Adding and updating records in the Site Registry</a></li>
      </ul>
      
      <h2 id="h2-0">QUT-wide</h2>
      <ul>
        <li><v-link href="/">All QUT websites</v-link></li>
        <li><v-link href="/areas">Area contacts</v-link></li>
      </ul>
      
      <h2 id="h2-1">What goes into the site registry?</h2>
      
      <p>The short version: if it has a separate domain or subdomain, it gets a separate entry in the Site Registry. For example, intranet.qut.edu.au and blogs.intranet.qut.edu.au are separate entries in the site registry.</p>
      
      <p>Sometimes a site may be a directory on another site, e.g. www.qut.edu.au/sitename. The default is that a site includes all directories on that domain.</p>
      
      <p>There should only be one record per site; aliases should be listed in the 'aliases' field of the site record.</p>

      <p>Information systems and technical systems with domains go into the Site Registry if there is a web browser interface to that system which is used outside the department which owns and maintains the system. For example, Department A has an online system called Application B which has the domain applicationb.qut.edu.au. If there is a web interface for Application B and people outside Department A use that web interface, the site applicationb.qut.edu.au should be listed in the Site Registry.</p>
      
      <h2 id="h2-2">Fields in the site registry</h2>

      <p>Registry ID: just the database ID for the site.</p>     
      <p>Site URL: this is the production URL, even if the site is not yet live.</p>
      <p>Aliases: list of any aliases that point to the same site</p>
      <p>Site name: we take this from the page title on the site home page, if the information is not provided by the business contact.</p>
    
      <p>Site status:</p>
      <ul>
        <li v-for="siteStatus in Object.keys(this.siteStatuses)" :key="siteStatus">
          <v-link :href="'/?statuses=' + siteStatus.toLowerCase()" :class="'text-' + siteStatuses[siteStatus].bsColour"> {{ siteStatus }} </v-link> - {{ siteStatuses[siteStatus].description }}</li> 
      </ul>
     
      
      <p>Site type: This is so we know what standards and policies apply to a site. The types are:</p>

      <ul>
        <li v-for="siteType in Object.keys(this.siteTypes)" :key="siteType">
          <v-link :href="'/?types=' + siteType.toLowerCase()">{{ siteType }}</v-link> - {{ siteTypes[siteType].description }}
        </li>
      </ul>
      
      <p>Business Contact: The primary business contact for an individual site.</p>
      <p>Business Area: The QUT organisational unit to whom the site belongs - the faculty, institute, or division. This is so that we know what part of QUT the site belongs to.</p>
      <p>Tech Support Contact: The technical support contact for an individual site.</p>
      <p>Tech Support Area: The QUT organisational unit that provides technical support for the site - often a section of DBS but not always.</p> 
      <p>Notes: Any extra information about the site, such as special policy or technical information.</p>

      <h2 id="h2-3">Who to contact about a site</h2>

      <p>The person listed as business contact in the <v-link href="/">Site Registry</v-link> is the person who looks after the day-to-day running of a site. They may be different to the <v-link href="/areas?areaType=Business">business area contact</v-link>, who oversees the websites of an entire faculty, institute or division.</p>

      <h2 id="h2-4">Adding and updating records in the Site Registry</h2>

      <p style="margin-bottom:200px">If you have a correction for a site registry record or there's an existing site that's not in the registry, please submit it using the <a target=_blank href="https://heat2.qut.edu.au/HEAT/Login.aspx?ProviderName=QUT+SAML&Role=SelfService&Scope=SelfService&CommandId=NewServiceRequestByOfferingId&Tab=ServiceCatalog&Template=5411C85ED8F7488B835895A80F8E197E">Website Support form in Heat</a>.</p>


    </div>
  </main-layout>
</template>

<script>
import { Auth } from 'aws-amplify';
import MainLayout from '../layouts/MainLayout.vue';
import AlertBox from '../components/Alert.vue';
import VLink from '../components/VLink.vue';
import { siteTypes, siteStatuses } from '../modules/common.js';
import Cache from '@aws-amplify/cache';

export default {
  components: {
    MainLayout,
    AlertBox,
    VLink
  },
  async created() {
    try {
      const session = await Auth.currentSession();
      const groups = session.idToken.payload['cognito:groups'];
      if (groups.indexOf('admins') > -1) {
        this.isAdmin = true;
      }
      window.history.pushState(null, '', this.$root.currentRoute); // ensure the path in the browser address bar reflects the current route
    } catch (error) {
      this.processError(error,'danger');
    }

  },
  data() {
    return {
      isAdmin: false,
      alerts: [],   
    }
  },
  computed: {
    siteTypes() {
      return siteTypes;
    },
    siteStatuses() {
      return siteStatuses;
    }
  },
  methods: {
    processError(error,errorType) {
      if (error == 'No current user' || error.message == 'Cannot retrieve a new session. Please authenticate.') {
        Cache.setItem('pathBeforeAuth', location.pathname);  // cache url path so we can load the appropriate route when the user returns from auth
        Auth.federatedSignIn({provider: "QUT"});
      } else {
        
        this.alerts.push({
          show: true,
          message: error.message ? error.message : error,
          errorType: "alert-" + (errorType ? errorType : "danger")
        });
      }
    },
  },
  
}
</script>