/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://nh7xdz6g4jhlflwdnn4dhbbhom.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-f7peoqxyjneq3di5rwffmg5gn4",
    "aws_content_delivery_bucket": "siteregistry-20210317123000-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d1tf2ycaciyr9t.cloudfront.net",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_7IujMzuQ6",
    "aws_user_pools_web_client_id": "e8msr92e2pt532h54ui0rnk25",
    "oauth": {
        "domain": "qutdbssiteregprod.auth.ap-southeast-2.amazoncognito.com"
    }
};


export default awsmobile;
