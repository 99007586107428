<template>
  <main-layout>
    <div class="container">
      <div class="alert alert-danger" role="alert">
        Page not found.
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
export default {
  components: {
    MainLayout
  }
}
</script>