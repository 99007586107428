import Cache from '@aws-amplify/cache';

function sortedItems (jsonObj,sortBy,order) {
  function compare(a, b) {
    var valuea = "";
    var valueb = "";


    for (var item in sortBy) {
      valuea += a[sortBy[item]] == null ? "" : a[sortBy[item]].toLowerCase();
      valueb += b[sortBy[item]] == null ? "" : b[sortBy[item]].toLowerCase();
    }
    // set to desc in case if order is undefined.
    if (valuea < valueb)
      return (order == 'desc' ? 1 : -1);
    if (valuea > valueb)
      return (order == 'desc' ? -1 : 1);
    return 0;
  }
  
  return jsonObj.sort(compare);
}

/**
 * Updates the query string visible in the browser address bar
 * to match the specified filter object.
 * 
 * @param {Object} filter - The filter object from a page component (eg. SitesPage.vue, AreasPage.vue, PeoplePage.vue, DashboardPage.vue)
 */
function updateQueryStringToMatchFilters(filter) {

  let filterURL = [];
  let addToURL = "";

  // iterate over each filter prop and build up an array of key-value pairs
  for (const prop in filter) {
    if (filter[prop] && filter[prop].length > 0) {
      filterURL.push(prop + '=' + filter[prop].toString());
    }
  }

  // convert the array of key-value pairs into a URL query string
  addToURL = filterURL.length > 0 ? "?"+ filterURL.join("&") : "";

  // add the query string to the browser address bar
  if (addToURL == "") {
    history.replaceState('', "filters", window.location.pathname);
  } else {
    history.replaceState('', "filters", addToURL);
  }

}

/**
 * Updates the specified filter object to match the query string
 * present in the browser address bar, or to match the cached
 * querystring value if address bar contains no querystring.
 * 
 * @param {Object} filter - The filter object from a page component (eg. SitesPage.vue, AreasPage.vue, PeoplePage.vue, DashboardPage.vue)
 */
function updateFiltersToMatchQueryString(filter) {

  let params = [];
  let cachedParams = Cache.getItem('querystringBeforeAuth');
  Cache.removeItem('querystringBeforeAuth');

  if (cachedParams && window.location.search.length == 0) {
    params = cachedParams.substr(1).split('&');
  } else {
    params = window.location.search.substr(1).split('&');
  }

  // iterate over each query string key-value pair, setting the corresponding filter prop to match
  for (let i=0; i<params.length; i++) {
    let keyvalue = params[i].split('=');
    if (filter[keyvalue[0]] != undefined) {
      if (Array.isArray(filter[keyvalue[0]])) {
        filter[keyvalue[0]] = keyvalue[1].split(',');
      } else {
        filter[keyvalue[0]] = keyvalue[1];
      }
    }
  }

}

const siteTypes = {
  Standard: {
    description: "on QUT domains and using QUT templates"
  },
  Special: {
    description: "special purpose QUT sites which have only the standard banner and footer, not the utility bar, navigation bar and side navigation"
  },
  Associated: {
    description: "non-QUT sites hosted by QUT; should not have QUT domains or use QUT templates"
  },
  WebApp: {
    description: "system-driven sites"
  },
  Conference: {
    description: "site created for a conference"
  },
  MicroSite: {
    description: "special kind of site used for once off, short lived marketing purposes"
  },
  Unknown: {
    description: "this can be used as an interim measure for complicated cases; there should be a note explaining the situation"
  },
  NonQUT: {
    description: "not managed or hosted by QUT"
  }
}

const siteStatuses = {
  Development: {
    bsColour: "primary",
    cssColour: "#0d6efd",
    description: "currently being created (on the development web server but not on production)",
  },
  Live: {
    bsColour: "success",
    cssColour: "#198754",
    description: "currently available (on the production web server)"
  },
  Dead: {
    bsColour: "muted",
    cssColour: "#6c757d",
    description: "a previously live site which is no longer available but has not been decommissioned",
  },
  Decommissioned: {
    bsColour: "warning-darker",
    cssColour: "#c19100",
    description: "a previously live site which has been removed at the request of the relevant Website Coordinator",
  }
};

const areaTypes = ['Business', 'Tech Support'];

export { sortedItems, updateQueryStringToMatchFilters, updateFiltersToMatchQueryString, siteStatuses, siteTypes, areaTypes };