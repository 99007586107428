<template>
  <div class="chart-container" style="position: relative; width:100%">
    <canvas :id="chartId" width="100%"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  chartInstance: {},
  props: ['chartId', 'chartType', 'chartData', 'chartOptions'],
  mounted() {
    var ctx = document.getElementById(this.chartId);
    this.chartInstance = new Chart(ctx, {
      type: this.chartType,
      data: this.chartData,
      options: this.chartOptions
    });
  },
  updated() {
    this.chartInstance.data = this.chartData;
    this.chartInstance.options = this.chartOptions;
    this.chartInstance.update();
  }

}
</script>

<style>
</style>