/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      url
      isHttps
      aliases
      status
      type
      notes
      reviewDate
      decommissionDate
      createdAt
      updatedAt
      techSupportArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      businessArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      techSupportContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      businessContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      url
      isHttps
      aliases
      status
      type
      notes
      reviewDate
      decommissionDate
      createdAt
      updatedAt
      techSupportArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      businessArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      techSupportContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      businessContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      url
      isHttps
      aliases
      status
      type
      notes
      reviewDate
      decommissionDate
      createdAt
      updatedAt
      techSupportArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      businessArea {
        id
        name
        type
        email
        createdAt
        updatedAt
        primaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
        secondaryContact {
          id
          username
          firstname
          lastname
          createdAt
          updatedAt
        }
      }
      techSupportContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      businessContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      name
      type
      email
      createdAt
      updatedAt
      primaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      secondaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      name
      type
      email
      createdAt
      updatedAt
      primaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      secondaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      name
      type
      email
      createdAt
      updatedAt
      primaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
      secondaryContact {
        id
        username
        firstname
        lastname
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      createdAt
      updatedAt
    }
  }
`;
