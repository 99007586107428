<template>

  <div class="container text-light bg-secondary pb-1 mb-4 rounded">

    <div class="row mb-3 pt-4">
      <div class="col-3">
        <strong>Filter by:</strong>
      </div>
      <div class="col-9 text-end" :class="{invisible: !showReset}">
        <button @click="resetFilter()" class="btn btn-primary btn-sm text-end">Reset</button>
      </div>
    </div>
    
    <!-- Sites filter -->
    <div v-if="filterType=='sites'">

      <div class="row mb-4">
        <label for="type" class="col-md-1 col-xs-12 form-group pr-0 mb-0">Type:  </label>
        <div class="col-md-10 col-xs-12">
          <div v-for="item in Object.keys(siteTypes)" :key="item" class="form-check-inline mr-3">
            <input type="checkbox" v-model="filter.types" :value="item.toLowerCase()" class="form-check-input mt-1" :id="'siteTypeFilter' + item">
            <label :for="'siteTypeFilter'+item" class="form-check-label ps-1">{{ item }}</label>
          </div>
        </div>
      </div>
      
      <div class="row mb-4">
        <label for="status" class="col-md-1 form-group pr-0 mb-0"> Status:  </label>
        <div class="col-md-11">
          <div v-for="item in Object.keys(siteStatuses)" :key="item" class="form-check-inline mr-3">
            <input type="checkbox" v-model="filter.statuses" :value="item.toLowerCase()" class="form-check-input mt-1" :id="'siteStatusFilter' + item">
            <label :for="'siteStatusFilter'+item" class="form-check-label ps-1">{{ item }}</label>
          </div>
          <span :class="{'invisible': filter.statuses.indexOf('decommissioned') == -1}">
            <select v-model="filter.decommissionDateYear" class="form-control d-inline w-auto me-2" id="decommissionDateYearFilter">
              <option value="">Any year</option>
              <option v-for="n in (new Date().getFullYear() - 2005 + 1)" :key="n">{{ n + 2005 - 1 }}</option>
            </select>
            <select v-model="filter.decommissionDateMonth" :class="{'invisible': filter.decommissionDateYear == ''}" class="form-control d-inline w-auto" id="decommissionDateMonthFilter">
              <option value="">Any month</option>
              <option v-for="n in 12" :key="n" :value="n-1">{{ ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][n-1] }}</option>
            </select>
          </span>
        </div>
      </div>
      
      <div class="row mb-4">
        <div class="col-md-3 form-group">
          <label for="searchBox">Search: </label>
          <input type="text" v-model="filter.searchText" class="form-control" id="searchBox" ref="searchBox">
          <a class="col-md-2 text-grey text-decoration-none clearTextBox" title="clear text" style="cursor: pointer; margin-bottom: -33px" v-if="filter.searchText" @click="[filter.searchText='',textFocus('searchBox')]">×</a>
        </div>
        <div class="col-md-3 form-group">
          <label for="areaPrimaryContactIdFilter">Business/Tech Area:</label>
          <select v-model="filter.siteAreaId" class="form-control" id="siteAreaIdFilter">
            <option value=""></option>
            <option v-for="area in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
          </select>
        </div>
        <div class="col-md-2 form-group">
          <label for="dueForReviewFilter">Due for Review:</label>
          <select v-model="filter.dueForReview" class="form-control" id="dueForReviewFilter">
            <option value=""></option>
            <option value="now">now</option>
            <option value="nextweek">next 7 days</option>
            <option value="nextmonth">next 1 month</option>
            <option value="next6months">next 6 months</option>
          </select>
        </div>
        <div class="col-md-2 form-group">
          <label for="sortBy">Sort By:</label>
          <select v-model="filter.sortBy" class="form-control" id="sortBy"> 
            <option value="decommissionDate">Date Decommissioned</option>
            <option value="name">Name</option>
            <option value="reviewDate">Review Date</option>
            <option value="url">URL</option>
          </select>
        </div>
        <div class="col-md-2 form-group">
          <label for="orderBy">Order By:</label>
          <select v-model="filter.orderBy" class="form-control" id="sortBy"> 
              <option value="asc">ASC</option>
              <option value="desc">DESC</option>
          </select>
        </div>
      </div>

    </div>

    <!-- Areas filter -->
    <div v-if="filterType=='areas'">

      <div class="row mb-4">
        <div class="col-md-4 form-group">
          <label for="areaNameFilter">Name:</label>
          <input type="text" v-model="filter.name" class="form-control" id="areaNameFilter" ref="searchBox">
          <a class="col-md-2 text-grey text-decoration-none clearTextBox" title="clear text" style="cursor: pointer; margin-bottom: -33px" v-if="filter.name" @click="[filter.name='',textFocus('searchBox')]">×</a>
        </div>
        <div class="col-md-3 form-group">
          <label for="areaPrimaryContactIdFilter">Primary Contact:</label>
          <select v-model="filter.areaPrimaryContactId" class="form-control" id="areaPrimaryContactIdFilter">
            <option value=""></option>
            <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
          </select>
        </div>
        <div class="col-md-3 form-group">
          <label for="areaSecondaryContactIdFilter">Secondary Contact:</label>
          <select v-model="filter.areaSecondaryContactId" class="form-control" id="areaSecondaryContactIdFilter">
            <option value=""></option>
            <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
          </select>
        </div>
      </div>

      <div class="row mb-4">
          <div class="col-md-1 form-group">
            <label>Type:</label>
          </div>
          <div class="col-md-11 mb-0">
            <div v-for="areaType in areaTypes" :key="areaType" class="form-check-inline mr-3">
              <input type="checkbox" v-model="filter.areaType" :value="areaType" :id="'areaTypeFilter' + areaType">
              <label :for="'areaTypeFilter' + areaType" class="ps-1">{{ areaType }}</label>
            </div>
        </div>
      </div>

    </div>

    <!-- People filter -->
    <div v-if="filterType=='people'">

      <div class="row mb-4">
        <div class="col-md-2">
          References:
        </div>
        <div class="col-md-10">
          <div class="form-check-inline mr-3">
            <input type="radio" v-model="filter.references" class="form-check-input mt-1" :id="'referencesFilterHasNoRefs'" value="HasNoRefs">
            <label :for="'referencesFilterHasNoRefs'" class="form-check-label ps-1">No references</label>
          </div>
          <div class="form-check-inline mr-3">
            <input type="radio" v-model="filter.references" class="form-check-input mt-1" :id="'referencesFilterHasNoCurrentSiteRefs'" value="HasNoCurrentSiteRefs">
            <label :for="'referencesFilterHasNoCurrentSiteRefs'" class="form-check-label ps-1">Not referenced in current sites</label>
          </div>
          <div class="form-check-inline mr-3">
            <input type="radio" v-model="filter.references" class="form-check-input mt-1" :id="'referencesFilterHasCurrentSiteRefs'" value="HasCurrentSiteRefs">
            <label :for="'referencesFilterHasCurrentSiteRefs'" class="form-check-label ps-1">Referenced in current sites</label>
          </div>
          <div class="form-check-inline mr-3">
            <input type="radio" v-model="filter.references" class="form-check-input mt-1" :id="'referencesFilterHasAreaRefs'" value="HasAreaRefs">
            <label :for="'referencesFilterHasAreaRefs'" class="form-check-label ps-1">Referenced in areas</label>
          </div>
          <div class="d-inline-block">
            <button @click="this.filter.references = ''" class="btn btn-primary btn-sm" :class="{'invisible': this.filter.references == ''}">Clear</button>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label for="personFilter">Search:</label>
          <input type="text" v-model="filter.searchText" class="form-control" id="personFilter" ref="searchBox">
          <a class="col-md-2 text-grey text-decoration-none clearTextBox" title="clear text" style="cursor: pointer; margin-bottom: -33px" v-if="filter.searchText" @click="[filter.searchText='',textFocus('searchBox')]">×</a>
        </div>
      </div>

    </div>

    <div v-if="filterType=='dashboard'">

      <div class="row mb-4">
        <div class="col-md-6">
          <label for="filterBusinessArea">Business Area</label>
          <select v-model="filter.siteBusinessAreaId" id="filterBusinessArea" class="form-control" :class="{'text-muted': filter.siteBusinessAreaId == ''}">
            <option value="" class="text-muted">All areas</option>
            <option v-for="area in businessAreas" :key="area.id" :value="area.id" class="text-body">{{ area.name }}</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="filterTechSupportArea">Tech Support Area</label>
          <select v-model="filter.siteTechSupportAreaId" id="filterTechSupportArea" class="form-control" :class="{'text-muted': filter.siteTechSupportAreaId == ''}">
            <option value="" class="text-muted">All areas</option>
            <option v-for="area in techSupportAreas" :key="area.id" :value="area.id" class="text-body">{{ area.name }}</option>
          </select>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
  import { areaTypes, siteStatuses, siteTypes } from '../modules/common.js'

  export default {
    props: ['parentFilter', 'defaultFilterJson', 'persons', 'areas', 'filterType', 'dateFormat'],
    emits: ['update:parentFilter'],
    data() {
      return {
        filter: JSON.parse(JSON.stringify(this.parentFilter), this.dateFilter),   // assigning filter directly to this.parentFilter (or having the template reference parentFilter directly) would reult in the prop being mutated, which goes against Vue best practice
        areaTypes: areaTypes,
        siteStatuses: siteStatuses,
        siteTypes: siteTypes
      }
    },
    computed: {
      showReset() {
        return this.defaultFilterJson !== JSON.stringify(this.filter);
      },
      businessAreas() {
        return this.areas.filter(function(area) {
          return area.type ? area.type.indexOf('Business') > -1 : false;
        });
      },
      techSupportAreas() {
        return this.areas.filter(function(area) {
          return area.type ? area.type.indexOf('Tech Support') > -1 : false;
        });
      },
    },
    methods: {
      resetFilter() {
        this.filter = JSON.parse(this.defaultFilterJson);
      },
      dateFilter(key, value) {
        var reISO = /^(\d{4})-(\d{2})-(\d{2})$/;
        if (typeof value === 'string') {
          var a = reISO.exec(value);
          if (a) 
            return new Date(value + "T:00:00:00");
        }
        return value;
      },
      textFocus(el) {
        this.$refs[el].focus();
      },
    },
    watch: {
      parentFilter: {
        handler() {  // parentFilter is updated by the parent page when a new site, area or person is created, so it is necessary to update the local filter prop whenever that happens
          this.filter = JSON.parse(JSON.stringify(this.parentFilter), this.dateFilter);
          },
        deep: true
      },
      filter: {
        handler() {

          // if this is a site filter, clear decomissionDate filter values if 'decommissioned' status not ticked
          if (this.filter.statuses && this.filter.statuses.indexOf('decommissioned') == -1) {
            this.filter.decommissionDateYear = "";
            this.filter.decommissionDateMonth = "";
          }

          // emit the updated filter object to the parent component
          this.$emit('update:parentFilter', JSON.parse(JSON.stringify(this.filter), this.dateFilter));

        },
        deep: true
      }
    }

  }
</script>