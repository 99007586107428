<template>
  <main-layout :isAdmin="isAdmin">

    <alert-box :alertsMsgs="alerts"></alert-box>
    <loading :loading="load"></loading>

    <div class="container">

      <!-- Filter form -->

      <filter-form
        v-model:parentFilter="filter"
        :defaultFilterJson="defaultFilterJson"
        :areas="areas"
        filterType="dashboard"
      ></filter-form>

      <!-- Charts -->

      <div class="row mb-5">
        <div class="col-sm-4">
          <strong>Total Live Sites:</strong><br>
          <span class="display-4 text-success">{{ chartData.sitesByStatus.labels.indexOf('Live') > -1 ? chartData.sitesByStatus.datasets[0].data[chartData.sitesByStatus.labels.indexOf('Live')] : 0 }}</span>
        </div>
        <div class="col-sm-4 text-sm-center">
          <strong>Total Development Sites:</strong><br>
          <span class="display-4 text-primary">{{ chartData.sitesByStatus.labels.indexOf('Development') > -1 ? chartData.sitesByStatus.datasets[0].data[chartData.sitesByStatus.labels.indexOf('Development')] : 0 }}</span>
        </div>
        <div class="col-sm-4 text-md-end">
          <strong>Total Sites:</strong><br>
          <span class="display-4">{{ sitesFiltered.length }}</span>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-6">
          <strong>Sites by Review Month</strong>
          <chart chartId="sitesByReviewMonth" chartType="bar" :chartData="chartData.sitesByReviewMonth" :chartOptions="chartOptions"></chart>
        </div> 
        <div :class="{'invisible': this.filter.siteTechSupportAreaId != ''}" class="col-md-6">
          <strong>Live Sites By Tech Support Area</strong>
          <chart chartId="liveSitesByTechSupportArea" chartType="bar" :chartData="chartData.liveSitesByTechSupportArea" :chartOptions="chartOptions"></chart>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-6">
          <strong>Sites By Status</strong>
          <chart chartId="sitesByStatus" chartType="bar" :chartData="chartData.sitesByStatus" :chartOptions="chartOptions"></chart>
        </div> 
        <div class="col-md-6">
          <strong>Live Sites By Type</strong>
          <chart chartId="liveSitesByType" chartType="bar" :chartData="chartData.liveSitesByType" :chartOptions="chartOptions"></chart>
        </div>
      </div>

      <div :class="{'invisible': this.filter.siteBusinessAreaId != ''}" class="row mb-5">
        <div class="col">
          <strong>Live Sites By Business Area</strong>
          <chart chartId="liveSitesByBusinessArea" chartType="bar" :chartData="chartData.liveSitesByBusinessArea" :chartOptions="chartOptions"></chart>
        </div> 
      </div>

    </div>

  </main-layout>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { listSites, listAreas, listPersons } from '../graphql/queries';
import MainLayout from '../layouts/MainLayout.vue';
import AlertBox from '../components/Alert.vue';
import Loading from '../components/LoadingSpinner.vue'; 
import Chart from '../components/Chart.vue';
import FilterForm from '../components/FilterForm.vue';
import { siteStatuses, updateQueryStringToMatchFilters, updateFiltersToMatchQueryString } from '../modules/common.js';
import Cache from '@aws-amplify/cache';

export default {
  components: {
    MainLayout,
    AlertBox,
    Loading,
    Chart,
    FilterForm,
  },
  beforeMount() {
    this.defaultFilterJson = JSON.stringify(this.filter);
    updateFiltersToMatchQueryString(this.filter);
  },
  async created() {
    try {
      const session = await Auth.currentSession();
      const groups = session.idToken.payload['cognito:groups'];
      if (groups.indexOf('admins') > -1) {
        this.isAdmin = true;
      }
      window.history.pushState(null, '', this.$root.currentRoute); // ensure the path in the browser address bar reflects the current route
      this.getSites();
      this.getAreas();
      this.getPersons();
    } catch (error) {
      this.processError(error,'danger');
    }

  },
  data() {
    return {
      isAdmin: false,
      alerts: [],   
      load: true,
      sites: [],
      areas: [],
      people: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
      },
      filter: {
        siteBusinessAreaId: "",
        siteTechSupportAreaId: ""
      },
      defaultFilterJson: ""
    }
  },
  watch: {
    sitesFiltered: function() {
      updateQueryStringToMatchFilters(this.filter);
    }
  },
  computed: {
    chartData() {

      // define chart data structure
      var chartdata = {
        liveSitesByType: {
          labels: [],
          datasets: [{
            label: 'Site Count',
            data: [],
            backgroundColor: ['#003f5c','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
          }]
        },
        sitesByStatus: {
          labels: [],
          datasets: [{
            label: 'Site Count',
            data: [],
            backgroundColor: [],  //using status colors; these are populated below when populating chart data
          }]
        },
        liveSitesByTechSupportArea: {
          labels: [],
          datasets: [{
            label: 'Site Count',
            data: [],
            backgroundColor: ['#003f5c','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
          }]
        },
        liveSitesByBusinessArea: {
          labels: [],
          datasets: [{
            label: 'Site Count',
            data: [],
            backgroundColor: ['#003f5c','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
          }]
        },
        sitesByReviewMonth: {
          labels: this.next12MonthsYYYYMM(),
          datasets: [{
            label: 'Site Count',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: ['#003f5c','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
          }]
        }
      };

      // populate chart data
      for (let i=0; i<this.sitesFiltered.length; i++) {

        // sitesByStatus
        var siteStatus = this.sitesFiltered[i].status ? this.sitesFiltered[i].status : "Unknown";
        if (chartdata.sitesByStatus.labels.indexOf(siteStatus) > -1 ) {
          chartdata.sitesByStatus.datasets[0].data[chartdata.sitesByStatus.labels.indexOf(siteStatus)] += 1;
        } else {
          chartdata.sitesByStatus.labels.push(siteStatus);
          chartdata.sitesByStatus.datasets[0].data.push(1);
          chartdata.sitesByStatus.datasets[0].backgroundColor.push(siteStatuses[siteStatus] ? siteStatuses[siteStatus].cssColour : "#dc3545");
        }

        // liveSitesByType
        if (this.sitesFiltered[i].status && this.sitesFiltered[i].status == "Live") {
          let siteType = this.sitesFiltered[i].type ? this.sitesFiltered[i].type : "Unknown";
          if (chartdata.liveSitesByType.labels.indexOf(siteType) > -1 ) {
            chartdata.liveSitesByType.datasets[0].data[chartdata.liveSitesByType.labels.indexOf(siteType)] += 1;
          } else {
            chartdata.liveSitesByType.labels.push(siteType);
            chartdata.liveSitesByType.datasets[0].data.push(1);
          }
        }

        // liveSitesByTechSupportArea
        if (this.sitesFiltered[i].status && this.sitesFiltered[i].status == "Live") {
          let siteTechSupportArea = this.sitesFiltered[i].techSupportArea ? this.sitesFiltered[i].techSupportArea.name : "Unknown";
          if (chartdata.liveSitesByTechSupportArea.labels.indexOf(siteTechSupportArea) > -1 ) {
            chartdata.liveSitesByTechSupportArea.datasets[0].data[chartdata.liveSitesByTechSupportArea.labels.indexOf(siteTechSupportArea)] += 1;
          } else {
            chartdata.liveSitesByTechSupportArea.labels.push(siteTechSupportArea);
            chartdata.liveSitesByTechSupportArea.datasets[0].data.push(1);
          }
        }

        // liveSitesByBusinessArea
        if (this.sitesFiltered[i].status && this.sitesFiltered[i].status == "Live") {
          let siteBusinessArea = this.sitesFiltered[i].businessArea ? this.sitesFiltered[i].businessArea.name : "Unknown";
          if (chartdata.liveSitesByBusinessArea.labels.indexOf(siteBusinessArea) > -1 ) {
            chartdata.liveSitesByBusinessArea.datasets[0].data[chartdata.liveSitesByBusinessArea.labels.indexOf(siteBusinessArea)] += 1;
          } else {
            chartdata.liveSitesByBusinessArea.labels.push(siteBusinessArea);
            chartdata.liveSitesByBusinessArea.datasets[0].data.push(1);
          }
        }

        // sitesByReviewMonth
        if (!this.sitesFiltered[i].status || this.sitesFiltered[i].status != "Decommissioned") {
          let siteReviewDateYYYYMM = this.sitesFiltered[i].reviewDate ? this.sitesFiltered[i].reviewDate.substr(0,7) : "Unknown";
          if (chartdata.sitesByReviewMonth.labels.indexOf(siteReviewDateYYYYMM) > -1 ) {
            chartdata.sitesByReviewMonth.datasets[0].data[chartdata.sitesByReviewMonth.labels.indexOf(siteReviewDateYYYYMM)] += 1;
          } else {
            chartdata.sitesByReviewMonth.labels.push(siteReviewDateYYYYMM);
            chartdata.sitesByReviewMonth.datasets[0].data.push(1);
          }
        }

      }

      // sort chart data
      this.sortChartData(chartdata.liveSitesByType, 'data', 'desc');
      this.sortChartData(chartdata.sitesByStatus, 'data', 'desc', true);
      this.sortChartData(chartdata.liveSitesByTechSupportArea, 'data', 'desc');
      this.sortChartData(chartdata.liveSitesByBusinessArea, 'data', 'desc');
      this.sortChartData(chartdata.sitesByReviewMonth, 'label', 'asc');

      return chartdata;
    },

    sitesFiltered() {
      return this.sites.filter(this.siteFilter, this)
    },

  },
  methods: {

    siteFilter(site) {  
      return true &&
          (this.filter.siteBusinessAreaId == "" || (site.businessArea && site.businessArea.id && site.businessArea.id == this.filter.siteBusinessAreaId)) &&
          (this.filter.siteTechSupportAreaId == "" || (site.techSupportArea && site.techSupportArea.id && site.techSupportArea.id == this.filter.siteTechSupportAreaId));
    },

    processError(error,errorType) {
      if (error == 'No current user' || error.message == 'Cannot retrieve a new session. Please authenticate.') {
        Cache.setItem('querystringBeforeAuth', location.search);  // cache filter params in querystring, since these can't be sent to Cognito
        Cache.setItem('pathBeforeAuth', location.pathname);  // cache url path so we can load the appropriate route when the user returns from auth
        Auth.federatedSignIn({provider: "QUT"});
      } else {
        
        this.alerts.push({
          show: true,
          message: error.message ? error.message : error,
          errorType: "alert-" + (errorType ? errorType : "danger")
        });
      }
    },

    async getSites() {
      this.load = true
      try {
        const sites = await API.graphql({
          query: listSites,
          variables: {
            limit: 1000,

          }
        });
  
        this.sites = sites != undefined ? sites.data.listSites.items : [];
      
      } catch(error) {
        this.processError(error,'danger');
      }
      this.load = false
    },

    async getAreas() {
      try {
        const areas = await API.graphql({
          query: listAreas
        });
        this.areas = areas != undefined ?  areas.data.listAreas.items : [];
      } catch(error) {
         this.processError(error,'danger');
      }
    },

    async getPersons() {
      try {
        const persons = await API.graphql({
          query: listPersons,
          variables: {
            limit: 1000,
          }
        });
        this.persons = persons != undefined ? persons.data.listPersons.items : [];
      } catch(error) {
         this.processError(error,'danger');
      }
    },

    sortChartData(chartData, sortBy, sortDir, sortColours) {

      sortBy = sortBy ? sortBy : "data";
      sortDir = sortDir ? sortDir : "desc";
      sortColours = sortColours != undefined ? sortColours : false;
      var sortDirInt = sortDir == "desc" ? 1 : -1;

      var labelArray = Object.assign([], chartData.labels);
      var dataArray = Object.assign([], chartData.datasets[0].data);
      var colourArray = Object.assign([], chartData.datasets[0].backgroundColor);

      var unsortedArray = labelArray.map(function(d, i) {
        return {
          label: d,
          data: dataArray[i] || 0,
          colour: sortColours ? colourArray[i] : undefined
        };
      });

      var sortedArray = unsortedArray.sort(function(a, b) {
        if (sortBy == "data") {
          return sortDirInt * ( b.data - a.data );
        } else {
          return sortDirInt * ( b.label.localeCompare(a.label ) );
        }
      });

      chartData.labels = [];
      chartData.datasets[0].data = [];
      if (sortColours) {
        chartData.datasets[0].backgroundColor = [];
      }
      sortedArray.forEach(function(d){
        chartData.labels.push(d.label);
        chartData.datasets[0].data.push(d.data);
        if (sortColours) {
          chartData.datasets[0].backgroundColor.push(d.colour);
        }
      })

    },

    next12MonthsYYYYMM() {
      let date = new Date();
      let months = [];
      for (let i=0; i<12; i++) {
        months.push(date.getFullYear().toString() + '-' + (date.getMonth()+1).toString().padStart(2,'0'));
        date.setMonth(date.getMonth()+1);
      }
      return months;
    },
    
  }
  
}
</script>