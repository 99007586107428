<template>
 <!-- Alerts -->
      <div class="alertbox">
         <transition-group name="staggered-fade" tag="div" :css="false" >
            <div
            v-for="alert in alertsMsgs"
            :key="alert"
            >
               <div v-if="alert.show" class="alert alert-dismissible fade show" role="alert" :class="alert.errorType" >
                  <strong>{{ alert.message }}</strong>
                  <button type="button" class="btn-close btn-sm" data-bs-dismiss="alert" aria-label="Close" @click="alert.show = !alert.show"></button>
               </div>
            </div>
         </transition-group>
    </div>
</template>

<script>
   export default {
      name: 'alertBox',
      props: {
          alertsMsgs: {}
      },
      data () {
         return {
            alerts: this.alertMsgs
         }
      },
      methods: {
         afterEnter(el) {
            setTimeout(function(){ 
             el.childNodes[0].remove();
            }, 5000);
         
         },
      }
   }
</script>

<style scoped>
   .alertbox {
      position: fixed;
      z-index: 1041;
      top: 5%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0;
   }
</style>