<template>
      <div class="container mb-3 pr-0 pl-0" >
        <div class="accordion">
          <div class="card" :class="{ 'border-primary' : isEditing }">

            <!-- card header -->
            <div class="card-header p-1 d-grid" :id="siteID" :title="siteHeadingTooltip" v-if="!createNew">
              <button class="btn btn-block text-left py-2" @click='expandCollapse()' :aria-controls="siteID+'body'" :aria-expanded="isExpanded">
                <div class="row">
                  <div class="col-11">
                    <div :class="'text-' + this.siteStatusBsColour + ' position-absolute'">&#9679;</div>
                    <div class='text-start ms-3'>
                      {{ siteHeading }}
                      <span v-if="dueForReview" class="badge bg-danger">Due for review</span>
                    </div>
                  </div>
                  <div class="col-1 text-end" v-html="this.arrow"></div>
                </div>
              </button>
            </div>

            <!-- card body -->
            <div v-if='isExpanded || createNew' :id="siteID+'body'" :aria-labelledby="siteID">
              <div class="card-body">

                <!-- editing view -->
                <div v-if="isAdmin && isEditing || createNew">
                  
                  <div class="form-group row mt-2">
                    <label :for="'sitename-' + site.id" class="col-sm-2 col-form-label">Site name:</label>
                    <div class="col-sm-10">
                      <input type="text" :id="'sitename-' + site.id" v-model="site.name" class="form-control" :class="{'is-invalid': isValidating && !site.name}">
                      <div v-if="isValidating && !site.name" class="invalid-feedback" style="display:block">
                        Please provide a site name.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label :for="'siteurl-' + site.id" class="col-sm-2 col-form-label">URL:</label>
                    <div class="col-sm-2">
                      <select v-model="site.isHttps" :id="'isHttps-' + site.id" class="form-control">
                        <option value="true">https://</option>
                        <option value="false">http://</option>
                      </select>
                    </div>
                    <div class="col-sm-7">
                      <input type="text" v-model="site.url" :id="'siteurl-' + site.id" @change="cleanURL" class="form-control" :class="{'is-invalid': isValidating && !site.url}">
                      <div v-if="isValidating && !site.url" class="invalid-feedback" style="display:block">
                        Please provide a url.
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group row mt-2">
                    <label class="col-sm-2 col-form-label">Aliases:</label>
                    <div class="col-md-8">
                      <div v-for="(item, i) in site.aliases" :key="i" class="row">
                          <div class="col-9 pr-0 mb-2">
                            <input type="text" v-model="site.aliases[i]" class="form-control">
                          </div>
                          <div class="col-1 p-0 text-start">
                            <a v-on:click="removeAlias(i);" style="cursor: pointer" class="text-danger h3 text-decoration-none" title="delete">&times;</a>
                          </div>
                      </div>
                      <button class="btn btn-primary btn-sm" @click="addNewAlias">Add new alias</button>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label :for="'sitestatus-' + site.id" class="col-sm-2 col-form-label">Status:</label>
                    <div class="col-sm-10">
                      <select v-model="site.status" :id="'siteStatus-' + site.id" class="form-control" ref="status" :class="['text-' + siteStatusBsColour, (isValidating && !site.status ? ' is-invalid' : '') ]">
                        <option v-for="item in Object.keys(siteStatuses)" :key="item" :class="'text-' + siteStatuses[item].bsColour">{{ item }}</option>
                      </select>
                      <div v-if="isValidating && !site.status" class="invalid-feedback" style="display:block">
                        Please provide a site status.
                      </div>
                    </div>
                  </div>
                  <div v-if="(decommissionDateObj != null || site.status=='Decommissioned')" class="form-group row mt-2">
                    <label :for="'decommissionDate-' + site.id" class="col-sm-2 col-form-label">Date Decommissioned:</label>
                    <div class="col-sm-10 pr-0">
                      <datepicker v-model="decommissionDateObj" placeholder="Click to pick a date" class="form-control" :inputFormat="dateFormat" :id="'decommissionDate-' + site.id" :class="{'is-invalid': isValidating && decommissionDateObj==null}" />
                      <a v-if="decommissionDateObj!=null" v-on:click="decommissionDateObj=null" style="cursor: pointer; margin-bottom:-33px" class="text-danger text-decoration-none clearTextBox" title="clear date">&times;</a>
                      <div v-if="isValidating && decommissionDateObj==null" class="invalid-feedback" style="display:block">
                        Please provide a decommissioned date.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label :for="'sitetype-' + site.id" class="col-sm-2 col-form-label">Type:</label>
                    <div class="col-sm-10">
                      <select v-model="site.type" :id="'siteType-' + site.id" class="form-control" :class="{'is-invalid': isValidating && !site.type}">
                        <option v-for="item in Object.keys(siteTypes)" :key="item">{{item}}</option>
                      </select>
                      <div v-if="isValidating && !site.type" class="invalid-feedback" style="display:block">
                        Please provide a site type.
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label :for="'sitenotes-' + site.id" class="col-sm-2 col-form-label">Notes:</label>
                    <div class="col-sm-10">
                      <textarea v-model="site.notes" :id="'sitenotes-' + site.id" class="form-control"></textarea>
                    </div>
                  </div>
                  <div v-if="site.status != 'Decommissioned'" class="form-group row mt-2">
                    <label :for="'reviewDate-' + site.id" class="col-sm-2 col-form-label">Review Date:</label>
                    <div class="col-sm-7 pr-0">
                      <datepicker v-model="reviewDateObj" placeholder="Click to pick a date" class="form-control" :inputFormat="dateFormat" :id="'reviewDate-' + site.id" :upperLimit="maxReviewDate" :class="{'is-invalid': isValidating && reviewDateObj==null}" />
                      <a v-if="reviewDateObj != null" v-on:click="reviewDateObj = null" style="cursor: pointer; margin-bottom:-33px" class="text-danger text-decoration-none clearTextBox" title="clear date">&times;</a>
                      <div v-if="isValidating && reviewDateObj==null" class="invalid-feedback" style="display:block">
                        Please provide a review date.
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <button @click="reviewDateObj = maxReviewDate" class="btn btn-sm btn-primary">12 months from now</button>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                      <label :for="'siteBusinessArea-' + site.id" class="col-sm-2 col-form-label">Business Area: </label>
                      <div class="col-sm-10">
                        <select v-model="site.siteBusinessAreaId" :id="'siteBusinessArea-' + site.id" class="form-control">
                          <option value=""></option>
                          <option v-for="area in businessAreas" :key="area.id" :value="area.id">{{ area.name }}</option>
                        </select>
                      </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label :for="'sitebusinesscontact-' + site.id" class="col-sm-2 col-form-label">Business Contact: </label>
                    <div class="col-sm-10">
                      <select v-model="site.siteBusinessContactId" :id="'sitebusinesscontact-' + site.id" class="form-control">
                        <option value=""></option>
                        <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                      <label :for="'siteTechSupportArea-' + site.id" class="col-sm-2 col-form-label">Tech Support Area: </label>
                      <div class="col-sm-10">
                        <select v-model="site.siteTechSupportAreaId" :id="'siteTechSupportArea-' + site.id" class="form-control">
                          <option value=""></option>
                          <option v-for="area in techSupportAreas" :key="area.id" :value="area.id">{{ area.name }}</option>
                        </select>
                      </div>
                  </div>                  
                  <div class="form-group row mt-2">
                    <label :for="'sitetechsupportcontact-' + site.id" class="col-sm-2 col-form-label">Tech Support Contact: </label>
                    <div class="col-sm-10">
                      <select v-model="site.siteTechSupportContactId" :id="'sitetechsupportcontact-' + site.id" class="form-control">
                        <option value=""></option>
                        <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.firstname }} {{ person.lastname }} ({{ person.username }})</option>
                      </select>
                    </div>
                  </div>
                 
                </div>

                <!-- read-only view -->
                <div v-else>
                  <p>URL: <a :href="urlWithProtocol" target="_blank">{{ site.url }}</a></p>
                  <p v-if="site.aliases.length > 0">Aliases: <ul><li v-for="alias in site.aliases" :key="alias">{{alias}}</li></ul></p>
                  <p>Status: {{ site.status }}</p>
                  <p v-if="decommissionDateObj !== null">Date Decommissioned: {{ decommissionDateString }}</p>
                  <p>Type: {{ site.type }}</p>
                  <p v-if="site.notes !== '' && site.notes !== null">Notes: <span style="white-space: pre-wrap">{{ site.notes }}</span></p>
                  <p v-if="decommissionDateObj == null && reviewDateObj !== null">Review Date: {{ reviewDateString }}</p>
                  <p v-for="area in areas" :key="area.id">
                    <span v-if="area.id==site.siteBusinessAreaId">Business Area: {{ area.name }}</span>
                  </p>
                  <p v-if="site.siteBusinessContactId">Business Contact: <span v-html="getPersonLink(site.siteBusinessContactId)"></span></p>
                  <p v-for="area in areas" :key="area.id">
                    <span v-if="area.id==site.siteTechSupportAreaId">Tech Support Area: {{ area.name }}</span>
                  </p>
                  <p v-if="site.siteTechSupportContactId">Tech Support Contact: <span v-html="getPersonLink(site.siteTechSupportContactId)"></span></p>
                </div>
        
                <!-- action buttons -->
                <div class="form-group mt-3 text-left" v-if="isAdmin">
                  <button v-if="!isEditing && !createNew" @click="startEditing" class="btn btn-primary me-2">Edit</button>
                  <button v-if="isEditing" @click="updateSite" class="btn btn-primary me-2" type="submit">Update</button>
                  <button v-if="isEditing && !createNew" @click="cancelEdit" class="btn btn-light me-2" type="button">Cancel</button>
                  <button v-if="isEditing || !createNew" @click="deleteSite" class="btn btn-danger" type="submit">Delete</button>
                </div>

              </div>
            </div>

          </div> <!-- card end -->
        </div> <!-- accordion end -->

        <!-- action buttons for 'add new site' form -->
        <div v-if="createNew" class="mt-2 pb-3">
          <button @click="createSite" class="btn btn-primary me-2" type="submit">Create</button>
          <button @click="cancelEdit" class="btn btn-light me-2" type="button">Reset</button>
        </div>

      </div>
</template>

<script>
import { API } from 'aws-amplify';
import { createSite, updateSite, deleteSite } from '../graphql/mutations';
import { format  } from 'date-fns';
import Datepicker from 'vue3-datepicker'
import { siteStatuses, siteTypes } from '../modules/common.js'

export default {
  components: {
    Datepicker,
  },
  emits: ['error', 'siteCreated', 'siteUpdated', 'siteDeleted'],
  props: {
  
    initialSite: {
      default: function() {
        return {}
      },
      type: Object,
    },
    areas: {
      default: function() {
        return []
      },
      type: Array
    },
    persons: {
      default: function() {
        return []
      },
      type: Array
    },
    isAdmin: {
      default: false,
      type: Boolean
    },
    dateFormat: {
      default: "dd-MM-yyyy",
      type: String
    },
   
  },
  async created() {
    if (this.createNew)  {
      this.siteBeforeEdit = JSON.stringify(this.site);
    }

  },
  data() {
    return {
      isExpanded: false,
      isEditing: false,
      isValidating: false,
      siteBeforeEdit: {},
      site: {
        id: this.initialSite.id ? this.initialSite.id : null,
        name: this.initialSite.name ? this.initialSite.name : null,
        url: this.initialSite.url ? this.initialSite.url : null,
        isHttps: this.initialSite.isHttps !== null ? this.initialSite.isHttps : true,
        notes: this.initialSite.notes ? this.initialSite.notes : null,
        siteBusinessAreaId: this.initialSite.businessArea ? this.initialSite.businessArea.id : null,
        siteBusinessContactId: this.initialSite.businessContact ? this.initialSite.businessContact.id : null,
        siteTechSupportAreaId: this.initialSite.techSupportArea ? this.initialSite.techSupportArea.id : null,
        siteTechSupportContactId: this.initialSite.techSupportContact ? this.initialSite.techSupportContact.id : null,
        decommissionDate: this.initialSite.decommissionDate && this.initialSite.decommissionDate != "1970-01-01" ? this.initialSite.decommissionDate : null,
        reviewDate: this.initialSite.reviewDate && this.initialSite.reviewDate != "1970-01-01" ? this.initialSite.reviewDate : null,
        aliases: this.initialSite.aliases ? JSON.parse(JSON.stringify(this.initialSite.aliases)) : [],
        status: this.initialSite.status ? this.initialSite.status : null,
        type: this.initialSite.type ? this.initialSite.type : null,
      }
    }
  },
  watch: {
    site: {
      handler(site) {
        if (site.status == "Decommissioned" && this.site.reviewDate != null) {
          this.site.reviewDate = null;
        } else {
          if (site.status != "Decommissioned" && this.site.decommissionDate != null) {
            this.site.decommissionDate = null;
          }
        }
      },
      deep: true
    }
  },
  computed: {
    reviewDateObj: {
      get() {
        return this.site.reviewDate ? new Date(this.site.reviewDate + "T00:00:00+10:00") : null;
      },
      set(newValue) {
        if (newValue != null) {
          this.site.reviewDate = format(newValue,'yyyy-MM-dd');
        } else {
          this.site.reviewDate = null;
        }
      }
    },
    decommissionDateObj: {
      get() {
        return this.site.decommissionDate ? new Date(this.site.decommissionDate + "T00:00:00+10:00") : null;
      },
      set(newValue) {
        if (newValue != null) {
          this.site.decommissionDate = format(newValue,'yyyy-MM-dd');
        } else {
          this.site.decommissionDate = null;
        }
      }
    },
    siteID() {
      return "a" + this.site.id;
    },
    siteHeadingTooltip() {
      return (this.site.status ? this.site.status : '') + 
      (this.decommissionDateObj != null ?  ' | ' + this.decommissionDateString : '')
    },
    siteHeading() {
      return this.site.name + " (" + this.site.url + ")";
    },
    createNew() {
      return this.site.id == null && this.isAdmin;
    },
    reviewDateString() {
      return this.reviewDateObj == null ? null : format(this.reviewDateObj,this.dateFormat);     
    },
    decommissionDateString() {
      return this.decommissionDateObj == null ? null : format(this.decommissionDateObj,this.dateFormat);     
    },
    urlWithProtocol() {
      let protocol = "https://";
      if (this.site.isHttps == false) {
        protocol = "http://";
      }
      return protocol + this.site.url;
    },
    arrow() {
      return (this.isExpanded ? '&#x25b2;' : '&#x25bc;')
    },
    siteTypes() {
      return siteTypes;
    },
    siteStatuses() {
      return siteStatuses;
    },
    siteStatusBsColour() {
      if (this.site.status && siteStatuses[this.site.status]) {
        return siteStatuses[this.site.status].bsColour;
      } else {
        return 'light';
      }
    },
    businessAreas() {
      return this.areas.filter(this.businessAreaFilter);
    },
    techSupportAreas() {
      return this.areas.filter(this.techSupportAreaFilter);
    },
    maxReviewDate() {
      let maxReviewDate = new Date();
      maxReviewDate.setHours(0, 0, 0, 0);
      maxReviewDate.setFullYear(maxReviewDate.getFullYear() + 1);
      return maxReviewDate;
    },
    dueForReview() {
      if (this.reviewDateObj != null) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return this.reviewDateObj.getTime() <= today.getTime();
      } else {
        return false;
      }
    }
  },
  methods: {
    async createSite() {
      let getNewID;
      this.isValidating = true;
      if (!this.site || !this.site.name || !this.site.url || !this.site.type || !this.site.status || (this.site.status != 'Decommissioned' && this.site.reviewDate == null) || (this.site.status == 'Decommissioned' && this.site.decommissionDate == null)) return;
      this.site.aliases = this.site.aliases.filter(Boolean); // remove empty aliases
      try {
        getNewID = await API.graphql({
          query: createSite,
          variables: {input: this.site},
        });
        this.isValidating = false;

        this.$emit('siteCreated');
        this.$emit("error","Site '" + getNewID.data.createSite.name + "' added", 'success', getNewID.data.createSite.url);
        this.cancelEdit();

      } catch(error) {
         this.$emit('error', error);
      }
    },
    async updateSite() {
      this.isValidating = true;
      if (!this.site || !this.site.id || !this.site.name || !this.site.url || !this.site.type || !this.site.status || (this.site.status != 'Decommissioned' && this.site.reviewDate == null) || (this.site.status == 'Decommissioned' && this.site.decommissionDate == null)) return;
      this.site.aliases = this.site.aliases.filter(Boolean); // remove empty aliases
      try {
        await API.graphql({
          query: updateSite,
          variables: {input: this.site},
        });
        this.isEditing = false;   
        this.$emit('siteUpdated');
        this.$emit("error", "Site '" + this.site.name + "' updated", "success");
      } catch(error) {
         this.$emit('error', error);
      }
    },
    async deleteSite() {
      if (!this.site || !this.site.id) return;
      if (confirm("Are you sure you wish to delete: \n'" + this.siteHeading + "'?")) {
        try {
          const input = {id: this.site.id};
          await API.graphql({
            query: deleteSite,
            variables: {input: input},
          });
          this.$emit('siteDeleted');
          this.$emit("error", "Site '" + this.site.name + "' deleted", "success");
 
        } catch(error) {
          this.$emit('error', error);
        }
      } 
     
    },
    async startEditing() {
      this.siteBeforeEdit = JSON.stringify(this.site);
      this.isEditing = true;
    },
    async cancelEdit() {
      
      if (!this.createNew) {
          this.isEditing = false;
      } else {
        this.isExpanded = false;
      }
      this.site = JSON.parse(this.siteBeforeEdit);
    },
    getPersonLink(personId) {
      let personObj, linkMarkup;
      if (personId) {
        personObj = this.persons.find(element => element.id == personId);
      }
      if (personObj) {
        let fullname = personObj.firstname ? personObj.firstname + ' ' + personObj.lastname : '';
        let username = personObj.username ? personObj.username : '';
        linkMarkup = username ? `<a href="https://staff.qut.edu.au/staff/${username}" target="_blank">${fullname} (${username})</a>` : fullname;
      }
      return linkMarkup;
    },
    cleanURL() {
      if (this.site.url.indexOf('https://') > -1) {
        this.site.url = this.site.url.replace('https://', '');
        this.site.isHttps = true;
      }
      if (this.site.url.indexOf('http://') > -1) {
        this.site.url = this.site.url.replace('http://','');
        this.site.isHttps = false;
      }
      if (this.site.url.length > 0 && this.site.url.substr(this.site.url.length-1,1) == '/') {
        this.site.url = this.site.url.substr(0,this.site.url.length-1);
      }
    },
    addNewAlias() {
      this.site.aliases.push("");
    },
    removeAlias(index) {
      this.site.aliases.splice(index, 1);
    },
    expandCollapse() {
      this.isExpanded = !this.isExpanded;
    },
    applyStatusColour(event) {
      let classLists = event.target.classList;
      classLists.length > 1 ? classLists.toggle( classLists[1]) : "";
      classLists.add(event.target.selectedOptions[0].className);
    },
    businessAreaFilter(area) {
      // include the area if it is the currently assigned business area, regardless of area type...
      if (this.initialSite.businessArea && this.initialSite.businessArea.id == area.id) {
        return true;
      //... otherwise only include it if the area type is 'Business'
      } else {
        return area.type ? area.type.indexOf('Business') > -1 : false;
      }
    },
    techSupportAreaFilter(area) {
      // include the area if it is the currently assigned tech support area, regardless of area type...
      if (this.initialSite.techSupportArea && this.initialSite.techSupportArea.id == area.id) {
        return true;
      //... otherwise only include it if the area type is 'Tech Support'
      } else {
        return area.type ? area.type.indexOf('Tech Support') > -1 : false;
      }
    }
  }
}
</script>
<style lang="scss">

  @media (max-width: 767px) { 
    .aliasDeleteIcon {
      position: absolute;
      text-align: end;
      left:13px;
    }
  }

</style>
