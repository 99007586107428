<template> 
	<div class="row">
		<div class="col-2 col-xs-12 mr-0">
			<span v-html="totalResults"></span>
		</div>
		<div class="col-md-7" v-if="totalResultCount > 0 && itemsPerPage > 0">
			<nav aria-label="Navigation for paged filter results">
				<ul class="pagination flex-wrap" >
					<li class="page-item" :class="{disabled: page == 1}">
						<button @click="getResultCount(page-1)" class="page-link" :tabindex="page == 1 ? -1 : undefined" :aria-disabled="page == 1 ? true : undefined"> 
							<span aria-hidden="true">&laquo;</span>
							<span class="sr-only">Previous</span>
						</button>
					</li>
					<li v-for="i in totalPages" :key="i" :class="{active: page == i}" :aria-current="page == i ? true : undefined" class="page-item">
						<button @click="getResultCount(i)" class="page-link">{{ i }}</button>
					</li>
					<li class="page-item" :class="{disabled: page == totalPages}"	>
						<button @click="getResultCount(page+1)" class="page-link" :tabindex="page == totalPages ? -1 : undefined" :aria-disabled="page == totalPages ? true : undefined">  
							<span aria-hidden="true">&raquo;</span>
							<span class="sr-only">Next</span>
						</button>
					</li>
				</ul>
			</nav>
		</div>
		<div class="col-md-3" v-if="sortRecordsDropDown.length > 0">
			<select class="form-control displayRecordsOption col-md-8" v-model="itemsPerPage" @change="changeDisplayOption()">
				<option :value="totalResultCount">All</option>
				<option :key="recordsToDisplay" v-for="recordsToDisplay in sortRecordsDropDown">{{recordsToDisplay}}</option>
			</select>
			<label class="col-md-4 align-middle">per page </labeL>
		</div>
	</div>
</template>

<script>

  export default {
    name: 'pagination',
	emits: ['update:currentPage','update:recordsPerPage','update:currentIndex'],
    props: {
		recordsPerPage: {
			default: 50,
			type: Number,
		},
		currentPage : {
			default: 0,
			type: Number,
		},
		currentCount: {
			type: Number,
		},
		totalResultCount: {
			type: Number,
		},
		zeroResultsMsg: {
			type: Boolean,
		},
		currentIndex: {
			type: Number
		},
	},
	
	watch: {
		currentPage: function(){		
			this.page = this.currentPage;
		}
	},
	computed: { 
		totalResults() {
			let noResultFoundMsg = !this.zeroResultsMsg ? "" : "No results found" ;
			let displayMsg =  (this.totalResultCount == this.currentCount) ? " all " : " ";
			let text = this.totalResultCount < 1 ? noResultFoundMsg : "Displaying" + displayMsg;
			let count = (this.currentPage * this.getRecordPerPage);
			count = count > this.totalResultCount ? this.totalResultCount : count;
			count = (this.currentIndex + 1) + '-' + count;
			return text + "<br>" + (this.totalResultCount > 0 ? count + " of "+ this.totalResultCount : "");
		},
		totalPages() {
			if (this.recordsPerPage == 0) return 1;
			else return Math.ceil(this.totalResultCount / this.recordsPerPage);
		},
		getRecordPerPage() {
			return this.recordsPerPage === 0 ? this.totalResultCount : this.recordsPerPage;
		},
	
		sortRecordsDropDown() {
			return this.filterRecordsDropDown()
		}
	},
		
	data() {
		return {
			page:  this.currentPage,
			itemsPerPage : this.recordsPerPage,
			recordsOptions: [25,50,100,200,this.recordsPerPage] //adding option to array for displayRecordsOption dropdown
		}
	},
	methods: {
		getResultCount(i) {
			this.page = i;
			let resultCount = (this.page-1) *  this.itemsPerPage;
			this.$emit('update:currentPage',this.page);
			this.$emit('update:currentIndex',resultCount);
		},

		changeDisplayOption() {
			this.$emit('update:recordsPerPage',parseInt(this.itemsPerPage));
			this.$emit('update:currentIndex',0);
			this.$emit('update:currentPage',1);
		},
		
		filterRecordsDropDown() { 
			this.itemsPerPage = (this.recordsPerPage === 0 || this.totalResultCount == this.currentCount) ? this.totalResultCount : this.recordsPerPage;
			//return sorted and filtered to DropDown
			//excludes repeated or not required options (eg if there are only 30 records, it will hide all options above 30)
			return this.recordsOptions.filter(recordsOption =>  recordsOption < this.totalResultCount )
										.sort(function(a, b) {
										return a - b;
									}).filter(function(item, pos, ary) {
										if (item > 0) return  !pos || item != ary[pos - 1] ;
									})
		}
	}
  }
</script>
<style scoped>
	.displayRecordsOption {
		width: auto;
		padding-left: 3px !important;
		padding-right: 3px !important;
		margin-right: 10px;
		float:left
	}
</style>